<template>
  <v-container class="restaurant-details">
    <!-- Google Ad -->
    <AdSense />

    <v-row class="mt-10 d-flex justify-center pb-16">
      <v-col cols="12" sm="11" md="8">
        <!-- Main Card -->
        <v-card class="main-card" elevation="2">
          <!-- Admin Edit Button -->
          <v-btn v-if="isAdmin" color="primary" class="admin-edit-btn" @click="moveToEdit()">
            <v-icon left>mdi-pencil</v-icon>
            {{ $t('restDetails.edit') }}
          </v-btn>

          <!-- Restaurant Image -->
          <div class="restaurant-image-container">
            <a href="#" @click.prevent="openFancybox(getPicture)">
              <v-img :src="getPicture" height="400" class="restaurant-image" :contain="!restDetails.logo">
                <v-row class="fill-height" align="end">
                  <v-col>
                    <div class="restaurant-title pa-4">
                      <h1 class="text-h4 white--text font-weight-bold mb-2">
                        {{ restDetails.name }}
                      </h1>
                      <div class="d-flex align-center">
                        <v-rating :value="parseFloat(restDetails.rates?.averageRating || 0)" color="amber" dense
                          half-increments readonly size="18"></v-rating>
                        <span class="ml-2 white--text">
                          ({{ restDetails.rates?.totalReviews || 0 }} {{ $t('restDetails.reviews') }})
                        </span>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-img>
            </a>
          </div>

          <!-- Quick Actions Bar -->
          <v-card class="quick-actions mx-4 mt-n5" elevation="3">
            <v-row no-gutters align="center" justify="space-around">
              <v-col cols="auto">
                <v-btn class="action-btn" text large @click="navigate()">
                  <v-icon color="primary">mdi-navigation</v-icon>
                  <span class="ml-2">{{ $t('restDetails.action_navigate') }}</span>
                </v-btn>
              </v-col>

              <v-divider vertical></v-divider>

              <v-col cols="auto">
                <!-- Mobile call button -->
                <v-btn v-if="$vuetify.breakpoint.smAndDown" class="action-btn" text large @click="call()"
                  :disabled="!restDetails.phone">
                  <v-icon color="success">mdi-phone</v-icon>
                  <span class="ml-2">{{ $t('restDetails.action_call') }}</span>
                </v-btn>
                <!-- Desktop show number button -->
                <v-btn v-else class="action-btn" text large @click="showNumber()" :disabled="!restDetails.phone">
                  <v-icon color="success">mdi-phone</v-icon>
                  <span class="ml-2">{{ $t('restDetails.action_call') }}</span>
                </v-btn>

                <!-- Modern phone dialog -->
                <v-dialog v-model="showPhoneDialog" max-width="400" transition="dialog-bottom-transition"
                  overlay-opacity="0.8">
                  <v-card class="rounded-lg" elevation="10">
                    <v-icon color="success darken-1" size="32" @click="showPhoneDialog = false">mdi-close</v-icon>
                    <v-card-title class="d-flex flex-column align-center pt-6">
                      <v-avatar color="success lighten-4" size="64" class="mb-4">
                        <v-icon color="success darken-1" size="32">mdi-phone</v-icon>
                      </v-avatar>
                      <span class="text-h5 font-weight-medium">{{ $t('restDetails.phone_number') }}</span>
                    </v-card-title>

                    <v-card-text class="text-center py-6">
                      <div class="phone-display pa-4 rounded-lg mx-auto mb-2"
                        style="max-width: 240px; background-color: #f5f5f5;">
                        <p class="text-h4 font-weight-bold success--text">{{ formatPhoneNumber(restDetails.phone) }}</p>
                      </div>
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions class="pa-4">
                      <v-btn block color="success" elevation="2" height="48" class="rounded-lg font-weight-bold"
                        @click="call()">
                        <v-icon left>mdi-phone-outgoing</v-icon>
                        {{ $t('restDetails.call_now') }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-col>

              <v-divider vertical></v-divider>

              <v-col cols="auto">
                <v-btn class="action-btn" text large :href="restDetails.website" target="_blank"
                  :disabled="!restDetails.website">
                  <v-icon color="warning">mdi-earth</v-icon>
                  <span class="ml-2">{{ $t('restDetails.action_website') }}</span>
                </v-btn>
              </v-col>

              <v-divider vertical></v-divider>

              <v-col cols="auto">
                <v-btn class="action-btn" text large @click="shareDialog = true">
                  <v-icon color="pink">mdi-share-variant</v-icon>
                  <span class="ml-2">{{ $t('restDetails.action_share') }}</span>
                </v-btn>
              </v-col>
            </v-row>
          </v-card>

          <!-- Main Content -->
          <v-card-text class="pt-8">
            <!-- Status Badge -->
            <div class="status-badge mb-6">
              <v-chip :color="isOpen === 'true' ? 'success' : 'error'" text-color="white" small
                v-if="isOpen !== 'isEmpty'">
                {{ isOpen === 'true' ? $t('restDetails.open') : $t('restDetails.close') }}
              </v-chip>
            </div>

            <!-- Info Grid -->
            <v-row>
              <!-- Details Section -->
              <v-col cols="12" md="4">
                <info-section :title="$t('restDetails.title_details')" :items="[
                  {
                    label: $t('restDetails.title_category'),
                    value: constants.categories[restDetails.category_web][$i18n.locale]
                  },
                  {
                    label: $t('restDetails.title_sub_category'),
                    value: restDetails.sub_category_web?.map(item =>
                      constants.sub_categories[item][$i18n.locale]).join(', ')
                  },
                  {
                    label: $t('restDetails.title_type'),
                    value: restDetails.type_web?.map(item =>
                      constants.types[item][$i18n.locale]).join(', ')
                  },
                  {
                    label: $t('restDetails.other_details'),
                    value: restDetails.other_details || '-'
                  }
                ]" />
              </v-col>

              <!-- Address Section -->
              <v-col cols="12" md="4">
                <info-section :title="$t('restDetails.title_address')" :items="[
                  {
                    label: $t('restDetails.street'),
                    value: restDetails.address
                  },
                  {
                    label: $t('restDetails.city'),
                    value: restDetails.city
                  },
                  {
                    label: $t('restDetails.country'),
                    value: restDetails.country
                  }
                ]" />
              </v-col>

              <!-- Hours Section -->
              <v-col cols="12" md="4">
                <info-section v-if="!isLoggedIn" :title="$t('restDetails.title_open_hours')">
                  <div class="mt-2">
                    {{ $t('restDetails.login_to_add_hours') }}
                    <router-link to="/login" class="font-weight-bold ml-1">
                      {{ $t('restDetails.login') }}
                    </router-link>
                  </div>
                </info-section>
                <info-section v-else :title="$t('restDetails.title_open_hours')" :content="openingHoursContent" />
              </v-col>
            </v-row>

            <!-- Kashrut Information -->
            <v-row class="mt-6">
              <v-col cols="12" md="8">
                <v-card outlined class="kashrut-card">
                  <v-card-title class="subtitle-1 font-weight-bold">
                    {{ $t('restDetails.title_kashrut') }}
                  </v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" md="6">
                        <div class="mb-4">
                          <div class="caption grey--text">{{ $t('restDetails.kashrut_name') }}</div>
                          <div class="body-1">
                            {{ constants.kashruyot[restDetails.kashrut.kashrut_name_web][$i18n.locale] }}
                          </div>
                        </div>
                        <div class="mb-4">
                          <div class="caption grey--text">{{ $t('restDetails.badatz_local') }}</div>
                          <div class="body-1">{{ restDetails.kashrut.local_kashrut_name }}</div>
                        </div>
                      </v-col>
                      <v-col cols="12" md="6">
                        <div class="mb-4">
                          <div class="caption grey--text">{{ $t('restDetails.kashrut_more_details') }}</div>
                          <div class="body-1">{{ restDetails.kashrut.kashrut_details }}</div>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col cols="12" md="4">
                <v-card outlined height="100%">
                  <v-card-title class="subtitle-1 font-weight-bold">
                    {{ $t('restDetails.kosher_certificate') }}
                  </v-card-title>
                  <v-card-text class="d-flex justify-center align-center">
                    <template v-if="restDetails.kashrut.certificate">
                      <a href="#"
                        @click.prevent="openFancybox(host + '/certificates' + restDetails.kashrut.certificate)">
                        <v-img :src="host + '/certificates' + restDetails.kashrut.certificate" max-height="200"
                          contain></v-img>
                      </a>
                    </template>
                    <template v-else>
                      <v-alert type="info" text class="mb-0">
                        {{ $t('restDetails.certificate_not_found') }}
                        <template v-if="!isLoggedIn">
                          <div class="mt-2">
                            {{ $t('restDetails.add_cert') }}
                            <router-link to="/login" class="font-weight-bold">
                              {{ $t('restDetails.login') }}
                            </router-link>
                          </div>
                        </template>
                      </v-alert>
                    </template>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <!-- Gallery -->
            <v-row class="mt-6">
              <v-col cols="12">
                <h2 class="subtitle-1 font-weight-bold mb-4">{{ $t('restDetails.gallery') }}</h2>
                <v-carousel :height="restDetails.pictures.length === 0 ? 100 : 400" hide-delimiter-background
                  show-arrows-on-hover>
                  <v-carousel-item v-if="restDetails.pictures.length === 0" class="d-flex align-center justify-center">
                    <div class="text-h5 grey--text">{{ $t('restDetails.pictures_not_found') }}</div>
                  </v-carousel-item>
                  <v-carousel-item v-for="(item, i) in arrayPictureTrim()" :key="i"
                    @click="showImageModal('pictures', item)">
                    <v-img :src="host + '/pictures' + item" height="400" cover></v-img>
                  </v-carousel-item>
                </v-carousel>
              </v-col>
            </v-row>

            <!-- Reviews Section -->
            <v-row class="mt-6">
              <v-col cols="12">
                <v-card outlined>
                  <v-card-title class="subtitle-1 font-weight-bold">
                    {{ $t('restDetails.reviews') }}
                    <span class="ml-2">({{ restDetails.rates?.totalReviews || 0 }})</span>
                  </v-card-title>

                  <!-- Add Review Section -->
                  <v-card-text v-if="isLoggedIn">
                    <v-rating v-model="rate" background-color="warning lighten-3" color="warning" large hover
                      @input="addUpdateRate()"></v-rating>
                    <v-textarea v-model="review" :label="$t('restDetails.write_review')" outlined
                      class="mt-4"></v-textarea>
                    <v-btn color="primary" @click="addUpdateReview()" :loading="progressShow">
                      {{ actionTitle }}
                    </v-btn>
                  </v-card-text>
                  <v-card-text v-else>
                    <v-alert type="info" text>
                      {{ $t('restDetails.login_to_review') }}
                      <router-link to="/login" class="font-weight-bold">
                        {{ $t('restDetails.login') }}
                      </router-link>
                    </v-alert>
                  </v-card-text>

                  <v-divider></v-divider>

                  <!-- Reviews List -->
                  <v-list three-line>
                    <template v-for="(review, index) in restDetails.rates.reviews">
                      <v-list-item :key="index">
                        <v-list-item-content>
                          <v-list-item-title class="subtitle-2 font-weight-medium">
                            {{ review.userName }}
                          </v-list-item-title>
                          <v-list-item-subtitle class="caption grey--text">
                            {{ review.date }}
                          </v-list-item-subtitle>
                          <v-list-item-subtitle class="mt-2">
                            {{ review.review }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider v-if="index < restDetails.rates.reviews.length - 1"
                        :key="`divider-${index}`"></v-divider>
                    </template>
                  </v-list>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Edit Button for Mobile -->
    <v-btn v-if="!isAdmin" fixed bottom right color="primary" fab class="mb-16 mr-4 hidden-sm-and-up"
      @click="editRest()">
      <v-icon>mdi-pencil</v-icon>
    </v-btn>

    <!-- Edit Button for Desktop -->
    <v-btn v-if="!isAdmin" fixed bottom right color="primary" class="mb-16 mr-4 hidden-xs-only" @click="editRest()">
      <v-icon left>mdi-pencil</v-icon>
      {{ $t('restDetails.edit_now') }}
    </v-btn>

    <!-- Modals and Dialogs -->
    <image-modal v-if="isImageModalVisible" :image-url="modalImageUrl" @close="closeImageModal"></image-modal>

    <share-dialog v-model="shareDialog" v-if="shareDialog" :itemObj="restDetails"></share-dialog>

    <snack-bar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></snack-bar>
  </v-container>
</template>

<script>
import ApiServices from '@/services/api.service'
import SnackBar from '@/components/widgets/snackBar'
import ShareDialog from '@/components/rest-details-page/dialogs/ShareDialog'
import CheckOpen from "@/util/CheckOpen"
import Auth from '@/services/auth.service.js'
import { Fancybox } from "@fancyapps/ui"
import AdSense from '@/components/widgets/AdSense.vue'
import "@fancyapps/ui/dist/fancybox/fancybox.css"
import Constants from '@/util/GetConstant'

export default {
  name: 'RestaurantDetails',

  components: {
    SnackBar,
    ShareDialog,
    AdSense,
    ImageModal: () => import('@/components/widgets/ImageModal.vue'),
    InfoSection: () => import('@/components/rest-details-page/InfoSection.vue')
  },

  data() {
    return {
      progressShow: false,
      snackbar: false,
      snackbarColorBt: "success",
      snacbarText: "",
      host: "",
      restDetails: {},
      shareDialog: false,
      isOpen: true,
      isImageModalVisible: false,
      modalImageUrl: "",
      review: "",
      rate: 0,
      isLoggedIn: false,
      isAdmin: false,
      constants: {},
      showPhoneDialog: false
    }
  },

  computed: {
    actionTitle() {
      return this.review
        ? this.$t('restDetails.edit_review')
        : this.$t('restDetails.save_review')
    },

    getPicture() {
      return this.restDetails.logo
        ? this.host + '/logo' + this.restDetails.logo
        : require('@/assets/default_logo.png')
    },
    openingHoursLoginContent() {
      return ` <div class="mt-2">
                    $t('restDetails.login_to_add_hours') }}
                    <router-link to="/login" class="font-weight-bold">
                      {{ $t('restDetails.login') }}
                    </router-link>
                  </div>`
    },
    openingHoursContent() {
      if (!this.restDetails.open_hours?.thursday?.open) {
        return "-"
      }

      return {
        sunday: `${this.restDetails.open_hours.sunday.open} - ${this.restDetails.open_hours.sunday.close}`,
        monday: `${this.restDetails.open_hours.monday.open} - ${this.restDetails.open_hours.monday.close}`,
        tuesday: `${this.restDetails.open_hours.tuesday.open} - ${this.restDetails.open_hours.tuesday.close}`,
        wednesday: `${this.restDetails.open_hours.wednesday.open} - ${this.restDetails.open_hours.wednesday.close}`,
        thursday: `${this.restDetails.open_hours.thursday.open} - ${this.restDetails.open_hours.thursday.close}`,
        friday: `${this.restDetails.open_hours.friday.open} - ${this.restDetails.open_hours.friday.close}`,
        saturday: `${this.restDetails.open_hours.saturday.open} - ${this.restDetails.open_hours.saturday.close}`
      }
    }
  },

  methods: {
    showSnackBar(message, color) {
      this.snacbarText = message
      this.snackbarColorBt = color
      this.snackbar = true
    },

    moveToEdit() {
      this.$router.push({
        name: "adminAccount",
        params: { _id: this.restDetails._id, tab: "עריכת מסעדה" }
      })
    },

    async getSingleRestDetails() {
      try {
        this.progressShow = true
        const idJSON = JSON.stringify({ id: this.$route.params.id, platform: "website" })
        const api = process.env.VUE_APP_BASE_URL + `/rest/get_single_rest`
        const res = await fetch(api, ApiServices.requestOptions("POST", idJSON, ""))

        this.progressShow = false
        const jsonObject = await res.json()

        if (res.status >= 400) {
          this.showSnackBar(this.$t('errors.data_fetch_error') + ": " + jsonObject.message, "error")
        } else if (res.status === 401) {
          localStorage.removeItem("token")
          this.isLoggedIn = false
        } else if (res.status === 200) {
          this.restDetails = jsonObject
          this.$emit('setRestName', this.restDetails.name)
          this.isOpenNow()
          this.getUserRate()
          this.getUserReview()
        }
      } catch (error) {
        this.progressShow = false
        this.showSnackBar(this.$t('errors.restaurant_fetch_error') + ": " + error, "error")
      }
    },

    async addUpdateRate() {
      try {
        const token = localStorage.getItem("token")
        const idJSON = JSON.stringify({ rate: this.rate, restId: this.restDetails._id })
        const api = process.env.VUE_APP_BASE_URL + `/user/add_update_rate`
        const res = await fetch(api, ApiServices.requestOptions("POST", idJSON, token))
        const jsonObject = await res.json()

        if (res.status === 400) {
          this.showSnackBar(this.$t('errors.rate_add_error') + ": " + jsonObject.message, "error")
        } else if (res.status === 401) {
          localStorage.removeItem("token")
          this.isLoggedIn = false
        } else if (res.status === 200) {
          this.showSnackBar(this.$t('success.rate_added'), "success")
        }
      } catch (error) {
        this.showSnackBar(this.$t('errors.rate_add_error') + ": " + error, "error")
      }
    },

    async addUpdateReview() {
      try {
        this.progressShow = true
        const token = localStorage.getItem("token")
        const idJSON = JSON.stringify({ review: this.review, restId: this.restDetails._id })
        const api = process.env.VUE_APP_BASE_URL + `/user/add_update_review`
        const res = await fetch(api, ApiServices.requestOptions("POST", idJSON, token))

        this.progressShow = false
        const jsonObject = await res.json()

        if (res.status >= 400) {
          this.showSnackBar(this.$t('errors.review_add_error') + ": " + jsonObject.message, "error")
        } else if (res.status === 201) {
          this.showSnackBar(this.$t('errors.review_inappropriate'), "error")
        } else if (res.status === 401) {
          localStorage.removeItem("token")
          this.isLoggedIn = false
        } else if (res.status === 200) {
          this.showSnackBar(this.$t('success.review_added'), "success")
        }
      } catch (error) {
        this.showSnackBar(this.$t('errors.review_add_error') + ": " + error, "error")
      }
    },

    navigate() {
      if (window.innerWidth < 600) {
        window.location.href = `geo:${this.restDetails.location.latitude},${this.restDetails.location.longitude}`
      } else {
        const modeOfTransportation = "driving"
        const googleMapsWebLink = `https://www.google.com/maps/dir/?api=1&destination=${this.restDetails.location.latitude},${this.restDetails.location.longitude}&travelmode=${modeOfTransportation}`
        window.open(googleMapsWebLink, '_blank')
      }
    },
    call() {
      // Close dialog if open
      this.showPhoneDialog = false;
      // Initiate the call
      window.location.href = `tel:${this.restDetails.phone}`;
    },
    showNumber() {
      this.showPhoneDialog = true;
    },
    formatPhoneNumber(phoneNumber) {
      // Basic formatting for phone numbers
      // You can customize this based on your region's phone format
      if (!phoneNumber) return '';

      // Remove non-numeric characters
      const cleaned = ('' + phoneNumber).replace(/\D/g, '');

      // Format based on length
      if (cleaned.length === 10) {
        // Format as XXX-XXX-XXXX for 10 digit numbers
        return cleaned.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
      } else if (cleaned.length > 10) {
        // Handle international numbers
        return cleaned.replace(/(\d{1,3})(\d{3})(\d{3})(\d{4})/, '+$1 $2-$3-$4');
      }

      // Return with basic formatting for other lengths
      return phoneNumber.replace(/(\d{3})(?=\d)/g, '$1-');
    },
    isOpenNow() {
      this.isOpen = CheckOpen.checkOpen(this.restDetails.open_hours)
    },

    getUserRate() {
      const userId = Auth.getUserId()
      const userRate = this.restDetails.rates.ratings.find(rate => rate.userId === userId)
      if (userRate) {
        this.rate = userRate.rate
      }
    },

    getUserReview() {
      const userId = Auth.getUserId()
      const userReview = this.restDetails.rates.reviews.find(review => review.userId === userId)
      if (userReview) {
        this.review = userReview.review
      }
    },

    showImageModal(type, url) {
      this.isImageModalVisible = true
      this.modalImageUrl = this.host + `/${type}` + url
    },

    closeImageModal() {
      this.isImageModalVisible = false
      this.modalImageUrl = ""
    },

    editRest() {
      if (Auth.checkAuth()) {
        this.$router.push({
          name: "userAccount",
          params: { _id: this.$route.params.id }
        })
      } else {
        this.$router.push({ name: "login" })
      }
    },

    arrayPictureTrim() {
      return this.restDetails.pictures.filter(pic => pic)
    },

    async loadAdSenseScript() {
      if (document.querySelector('script[src="//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"]')) {
        return
      }

      return new Promise((resolve, reject) => {
        const script = document.createElement('script')
        script.src = '//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js'
        script.async = true
        script.onload = resolve
        script.onerror = reject
        document.head.appendChild(script)
      })
    },

    async getConstants() {
      this.constants = await Constants.getConstant()
    },

    openFancybox(src) {
      Fancybox.show([
        {
          src,
          type: 'image'
        }
      ], {
        Toolbar: {
          display: {
            left: ["infobar"],
            middle: [
              "zoomIn",
              "zoomOut",
              "rotateCCW",
              "rotateCW",
            ],
          },
        }
      })
    }
  },

  async mounted() {
    try {
      await this.loadAdSenseScript()
        ; (adsbygoogle = window.adsbygoogle || []).push({})
    } catch (error) {
      console.error('Failed to load AdSense script:', error)
    }

    this.getSingleRestDetails()
    this.host = process.env.VUE_APP_BASE_URL
    this.isLoggedIn = Auth.checkAuth()
    this.isAdmin = Auth.getUserRole() === "admin"

    this.constants = this.$store.state.constant
    if (!this.constants?.types) {
      await this.getConstants()
    }
  }
}
</script>

<style lang="scss" scoped>
.restaurant-details {
  background-color: #f5f7fa;
}

.main-card {
  border-radius: 8px;
  overflow: hidden;
}

.admin-edit-btn {
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 2;
}

.restaurant-image-container {
  position: relative;

  .restaurant-image {
    border-radius: 8px 8px 0 0;
  }

  .restaurant-title {
    background: linear-gradient(to top, rgba(0, 0, 0, 0.7), transparent);
  }
}

.quick-actions {
  border-radius: 8px;
  background: white;

  .action-btn {
    min-width: 120px;
    height: 64px;

    .v-icon {
      margin-right: 8px;
    }
  }
}

.info-section {
  background: #f8f9fa;
  border-radius: 8px;
  padding: 16px;
  height: 100%;

  .section-title {
    font-size: 1.1rem;
    font-weight: 500;
    margin-bottom: 16px;
  }
}

.kashrut-card {
  height: 100%;
}

// RTL Support
[dir="rtl"] {
  .admin-edit-btn {
    right: auto;
    left: 16px;
  }

  .action-btn {
    .v-icon {
      margin-right: 0;
      margin-left: 8px;
    }
  }
}

// Responsive Design
@media (max-width: 600px) {
  .quick-actions {
    .action-btn {
      min-width: auto;
      padding: 0 8px;

      span {
        display: none;
      }
    }
  }

  .restaurant-title {
    h1 {
      font-size: 1.5rem !important;
    }
  }
}


// .phone-display {
//   border: 2px dashed rgba(76, 175, 80, 0.4);
//   transition: all 0.3s ease;
// }

// .phone-display:hover {
//   background-color: rgba(76, 175, 80, 0.1) !important;
//   transform: scale(1.02);
//   cursor: pointer;
// }

// /* Add pulse animation to the phone icon on hover */
// .v-avatar:hover .v-icon {
//   animation: pulse 1.5s infinite;
// }

// @keyframes pulse {
//   0% {
//     transform: scale(1);
//   }
//   50% {
//     transform: scale(1.1);
//   }
//   100% {
//     transform: scale(1);
//   }
// }</style>