<template>
  <v-app-bar app flat :height="appBarHeight" :elevation="isAppBarShrunk ? 2 : 0" class="modern-navbar"
    :class="{ 'shrink': isAppBarShrunk }">
    <!-- Logo Section -->
    <div class="brand-section">
      <v-img :src="require('@/assets/default_logo.png')" max-height="50" contain />
    </div>

    <v-spacer></v-spacer>

    <!-- Desktop Navigation -->
    <v-slide-x-transition>
      <div class="desktop-nav" v-show="$vuetify.breakpoint.mdAndUp">
        <v-tabs v-model="selectedItem" background-color="transparent" centered>
          <v-tab v-for="item in menu" :key="item.id" :to="item.link" v-if="item.show" class="nav-tab">
            <v-icon left small class="mr-1">{{ item.icon }}</v-icon>
            {{ item.title }}
          </v-tab>
        </v-tabs>
      </div>
    </v-slide-x-transition>

    <!-- Right Side Actions -->
    <v-spacer />
    <div class="actions-section">
      <!-- Language Selector -->
      <v-menu offset-y transition="slide-y-transition">
        <template v-slot:activator="{ on, attrs }">
          <v-btn text v-bind="attrs" v-on="on" class="language-btn">
            <v-img :src="currentFlag" max-width="24" class="mr-2" />
            <span class="hidden-sm-and-down">{{ currentLanguage }}</span>
          </v-btn>
        </template>

        <v-list>
          <v-list-item @click="changeLanguage('en')" class="language-item">
            <v-list-item-avatar size="24">
              <v-img :src="require('@/assets/flag_uk.png')" />
            </v-list-item-avatar>
            <v-list-item-title>{{ $t('nav.lang_en') }}</v-list-item-title>
          </v-list-item>

          <v-list-item @click="changeLanguage('he')" class="language-item">
            <v-list-item-avatar size="24">
              <v-img :src="require('@/assets/flag_israel.png')" />
            </v-list-item-avatar>
            <v-list-item-title>{{ $t('nav.lang_he') }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <!-- User Section -->
      <template v-if="isLoggedIn">
        <v-menu offset-y transition="slide-y-transition">
          <template v-slot:activator="{ on, attrs }">
            <v-btn text v-bind="attrs" v-on="on" class="user-btn">
              <v-avatar size="32" color="primary" class="mr-2">
                <span class="white--text">{{ userInitials }}</span>
              </v-avatar>
              <span class="hidden-sm-and-down">{{ userName }}</span>
              <v-icon right>mdi-chevron-down</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item @click="showLogs" v-if="role === 'admin'">
              <v-list-item-icon>
                <v-icon>mdi-history</v-icon>
              </v-list-item-icon>
              <v-list-item-title>View Logs</v-list-item-title>
            </v-list-item>

            <v-list-item @click="logOut">
              <v-list-item-icon>
                <v-icon>mdi-logout</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ $t('nav.logout') }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>

      <v-btn v-else color="primary" outlined @click="login" class="login-btn">
        {{ $t('nav.login') }}
      </v-btn>

      <!-- Mobile Menu -->
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" class="hidden-md-and-up ml-4" />

    </div>

    <!-- Mobile Navigation Drawer -->
    <v-navigation-drawer v-model="drawer" app temporary right :style="{ zIndex: 0 }">
      <v-list nav>
        <v-list-item-group>
          <v-list-item v-for="item in menu" :key="item.id" :to="item.link" @click="drawer = false" link>
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider class="my-2" />

          <v-list-item v-if="isLoggedIn" @click="logOut" link>
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('nav.logout') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </v-app-bar>
</template>

<script>
import Auth from '@/services/auth.service.js'

export default {
  name: 'ModernNavbar',

  data: () => ({
    drawer: false,
    menu: [],
    selectedItem: null,
    isAppBarShrunk: false,
    appBarHeight: 64,
    userName: "",
    email: "",
    role: "",
    isUser: false,
    isAdmin: false,
    isLoggedIn: false,
    currentFlag: null,
    currentLanguage: 'English'
  }),

  computed: {
    userInitials() {
      return this.userName
        .split(' ')
        .map(word => word[0])
        .join('')
        .toUpperCase()
    },

    regularMenu() {
      return [
        { id: "home", title: this.$t('nav.li_item_rest_list'), link: "/", show: true, icon: "mdi-home" },
        { id: "about", title: this.$t('nav.li_item_about'), link: "/about", show: true, icon: "mdi-information" },
      ]
    },

    userMenu() {
      return [
        ...this.regularMenu,
        { id: "user", title: this.$t('nav.li_item_user_account'), link: "/user-account", show: true, icon: "mdi-account" },
      ]
    },

    adminMenu() {
      return [
        ...this.regularMenu,
        { id: "manager", title: this.$t('nav.li_item_admin'), link: "/admin-account", show: true, icon: "mdi-shield-account" },
        { id: "statistics", title: this.$t('nav.li_item_statistics'), link: "/admin-statistics", show: true, icon: "mdi-chart-bar" },
      ]
    }
  },

  methods: {
    handleScroll() {
      this.isAppBarShrunk = window.scrollY > 20
    },

    logOut() {
      localStorage.removeItem("token")
      this.isAdmin = false
      this.isUser = false
      this.isLoggedIn = false
      this.userName = null
      this.email = null
      this.setNavListItems()
      this.drawer = false
    },

    login() {
      this.$store.commit('setLoggedIn', false)
      this.$router.push({ name: "login" })
    },

    setNavListItems() {
      if (Auth.checkAuth()) {
        this.userName = Auth.getUserName()
        this.email = Auth.getUserEmail()
        this.role = Auth.getUserRole()

        if (this.role === "admin") {
          this.menu = this.adminMenu
          this.isAdmin = true
          this.isLoggedIn = true
        } else if (this.role === "user") {
          this.menu = this.userMenu
          this.isUser = true
          this.isLoggedIn = true
        }
      } else {
        this.isLoggedIn = false
        this.menu = this.regularMenu
      }
    },

    showLogs() {
      if (this.role === "admin") {
        this.$router.push({ name: 'logs' })
      }
    },

    changeLanguage(lang) {
      const currentPath = window.location.pathname
      const domain = 'kosher-basviva.com'
      const url = lang === 'en'
        ? `https://en.${domain}${currentPath}`
        : `https://${domain}${currentPath}`

      window.location.href = url

      this.currentLanguage = lang === 'en' ? 'English' : 'עברית'
      this.currentFlag = require(`@/assets/flag_${lang === 'en' ? 'uk' : 'israel'}.png`)
    },
  },
  watch: {
    '$route.path': {
      immediate: true,
      handler(newPath) {
        this.selectedItem = newPath;
      }
    }
  }
  ,
  created() {
    this.setNavListItems()
    window.addEventListener('scroll', this.handleScroll)
    this.currentFlag = this.$vuetify.rtl
      ? require('@/assets/flag_israel.png')
      : require('@/assets/flag_uk.png')
    this.currentLanguage = this.$vuetify.rtl ? 'עברית' : 'English'
  },

  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
  }
}
</script>

<style lang="scss" scoped>
.modern-navbar {
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.95) !important;
  border-bottom: 1px solid rgba(201, 202, 202, 0.1);
  transition: all 0.3s ease;

  &.shrink {
    height: 56px !important;
  }
}

.brand-section {
  width: 160px;
  padding: 0 16px;
  flex-shrink: 0;
}

.desktop-nav {
  display: flex;
  justify-content: center;
}

.nav-tab {
  text-transform: none;
  font-weight: 500;
  letter-spacing: 0;
  min-width: 96px;

  &::before {
    display: none;
  }
}

.actions-section {
  display: flex;
  align-items: center;
  padding: 0 16px;
  flex-shrink: 0;
}

.language-btn,
.user-btn {
  height: 40px;
  text-transform: none;
  font-weight: 500;
}

.login-btn {
  height: 36px;
  text-transform: none;
  font-weight: 500;
  letter-spacing: 0;
}

.language-item {
  min-height: 48px;
}

// RTL Support
.v-application--is-rtl {
  .actions-section {
    flex-direction: row-reverse;
  }

  .nav-tab {
    .v-icon {
      margin-right: 0;
      margin-left: 4px;
    }
  }
}

// Mobile Drawer Styles
.v-navigation-drawer {
  .v-list-item {
    cursor: pointer;

    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }
  }
}
</style>