<template>
    <v-dialog v-model="openMode" max-width="500px" content-class="share-dialog">
        <v-card class="share-dialog-card">
            <!-- Header -->
            <v-card-title class="share-header">
                <v-btn icon small class="close-btn" @click="close">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <span class="text-h5">{{ $t('share.title') }}</span>
                <div class="spacer"></div>
            </v-card-title>

            <v-divider></v-divider>

            <!-- Share Options -->
            <v-card-text>
                <!-- Business Info Preview -->
                <div class="business-preview mb-6">
                    <v-card outlined>
                        <v-card-text>
                            <div class="d-flex align-center mb-2">
                                <v-avatar size="40" color="grey lighten-2">
                                    <v-img v-if="itemObj.logo" :src="itemObj.logo" alt="Logo"></v-img>
                                    <v-icon v-else>mdi-store</v-icon>
                                </v-avatar>
                                <div class="ml-3">
                                    <div class="text-h6">{{ itemObj.name }}</div>
                                    <div class="subtitle-2 grey--text">{{ itemObj.city }}</div>
                                </div>
                            </div>
                        </v-card-text>
                    </v-card>
                </div>

                <!-- Share Platforms -->
                <div class="text-subtitle-1 mb-4">{{ $t('share.share_via') }}</div>
                <div class="share-buttons d-flex justify-space-around mb-6">
                    <v-tooltip bottom v-for="platform in sharePlatforms" :key="platform.id">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn class="share-btn mx-2" :color="platform.color" fab elevation="2" v-bind="attrs"
                                v-on="on" @click="shareOn(platform.id)">
                                <v-icon >{{ platform.icon }}</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ platform.tooltip }}</span>
                    </v-tooltip>
                </div>

                <!-- Copy Link Section -->
                <v-divider class="mb-4"></v-divider>

                <div class="copy-link-section">
                    <div class="text-subtitle-1 mb-2">{{ $t('share.copy_link') }}</div>
                    <v-text-field v-model="getCurrentURL" outlined dense readonly hide-details class="copy-field"
                        :success="copied" :success-messages="copied ? $t('share.link_copied') : ''">
                        <template v-slot:append>
                            <v-btn icon small @click="copyLink" :color="copied ? 'success' : 'primary'"
                                class="copy-btn">
                                <v-icon>{{ copied ? 'mdi-check' : 'mdi-content-copy' }}</v-icon>
                            </v-btn>
                        </template>
                    </v-text-field>
                </div>
            </v-card-text>
        </v-card>

        <!-- Snackbar for notifications -->
        <v-snackbar v-model="snackbar" :color="snackbarColorBt" :timeout="3000" bottom>
            {{ snacbarText }}
            <template v-slot:action="{ attrs }">
                <v-btn text v-bind="attrs" @click="snackbar = false">
                    {{ $t('general.close') }}
                </v-btn>
            </template>
        </v-snackbar>
    </v-dialog>
</template>

<script>
export default {
    name: 'ShareDialog',

    props: {
        value: {
            type: Boolean,
            default: false
        },
        itemObj: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            snackbar: false,
            snackbarColorBt: "success",
            snacbarText: "",
            copied: false,
            sharePlatforms: [
                {
                    id: 'whatsapp',
                    icon: 'mdi-whatsapp',
                    color: 'green',
                    tooltip: this.$t('share.share_whatsapp')
                },
                {
                    id: 'telegram',
                    icon: 'mdi-send',
                    color: 'light-blue darken-1',
                    tooltip: this.$t('share.share_telegram')
                },
                {
                    id: 'email',
                    icon: 'mdi-email',
                    color: 'red lighten-1',
                    tooltip: this.$t('share.share_email')
                },
                {
                    id: 'sms',
                    icon: 'mdi-message-processing',
                    color: 'blue darken-2',
                    tooltip: this.$t('share.share_sms')
                }
            ]
        }
    },

    computed: {
        openMode: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        },

        getCurrentURL() {
            return window.location.href
        },

        shareMessage() {
            return `${this.$t('share.check_out')} ${this.itemObj.name} ${this.$t('share.in')} ${this.itemObj.city}\n${this.getCurrentURL}`
        },

        encodedShareMessage() {
            return encodeURIComponent(this.shareMessage)
        }
    },

    methods: {
        close() {
            this.openMode = false
            this.copied = false
        },

        async copyLink() {
            try {
                await navigator.clipboard.writeText(this.getCurrentURL)
                this.copied = true
                this.showSnackBar(this.$t('share.link_copied'), 'success')
                setTimeout(() => {
                    this.copied = false
                }, 3000)
            } catch (err) {
                this.showSnackBar(this.$t('share.copy_error'), 'error')
            }
        },

        shareOn(platform) {
            const urls = {
                whatsapp: `https://wa.me/?text=${this.encodedShareMessage}`,
                telegram: `https://t.me/share/url?url=${encodeURIComponent(this.getCurrentURL)}&text=${encodeURIComponent(this.shareMessage)}`,
                email: `mailto:?subject=${encodeURIComponent(this.itemObj.name)}&body=${this.encodedShareMessage}`,
                sms: `sms:?body=${this.encodedShareMessage}`
            }

            if (platform === 'sms' && /Android|iPhone/i.test(navigator.userAgent)) {
                window.location.href = urls[platform]
            } else {
                window.open(urls[platform], '_blank')
            }
        },

        showSnackBar(message, color) {
            this.snacbarText = message
            this.snackbarColorBt = color
            this.snackbar = true
        }
    }
}
</script>

<style lang="scss" scoped>
.share-dialog {
    border-radius: 8px;

    .share-dialog-card {
        border-radius: 8px;
        overflow: hidden;
    }

    .share-header {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 16px;

        .close-btn {
            position: absolute;
            left: 8px;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .business-preview {
        transition: all 0.3s ease;

        &:hover {
            transform: translateY(-2px);
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        }
    }

    .share-buttons {
        .share-btn {
            transition: all 0.3s ease;

            &:hover {
                transform: translateY(-2px);
                box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
            }
        }
    }

    .copy-link-section {
        .copy-field {
            border-radius: 8px;
            transition: all 0.3s ease;

            &:hover {
                box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
            }

            .copy-btn {
                transition: all 0.3s ease;
            }
        }
    }
}

// RTL Support
[dir="rtl"] {
    .share-header {
        .close-btn {
            left: unset;
            right: 8px;
        }
    }
}

// Mobile Optimizations
@media (max-width: 600px) {
    .share-dialog {
        margin: 16px;

        .share-buttons {
            flex-wrap: wrap;

            .share-btn {
                margin: 8px;
            }
        }
    }
}
</style>