<template>
  <div class="edit-restaurant-container">
    <v-card class="rounded-card card-shadow" elevation="0">
      <v-card-title class="d-flex justify-center pt-6 pb-2">
        <h1 class="text-h4 primary-color font-weight-bold">עריכת מסעדה</h1>
      </v-card-title>

      <v-card-text class="form-container">
        <!-- Search Section -->
        <v-row class="mb-6">
          <v-col cols="12" md="6">
            <v-autocomplete :items="arrayNames" v-model="searchSelected" label="חפש לפי שם מסעדה, יישוב או מדינה" filled
              rounded dense clearable append-icon="mdi-magnify" prepend-inner-icon="mdi-store-search"
              :menu-props="{ bottom: true, offsetY: true }"></v-autocomplete>
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field v-model="idSelected" @change="getRestDetails('by_id')" label="חיפוש לפי ID" filled rounded
              dense prepend-inner-icon="mdi-identifier"></v-text-field>
          </v-col>
        </v-row>

        <v-container>
          <v-form @submit.prevent="submitHandler" ref="form">
            <v-row>
              <!-- Basic Information Section -->
              <v-col cols="12">
                <div class="section-title primary-color">מידע בסיסי</div>
              </v-col>

              <v-col cols="12" sm="6">
                <v-text-field v-model="itemEdited.name" label="שם המסעדה" filled rounded dense
                  :rules="[v => !!v || 'שדה חובה']" prepend-inner-icon="mdi-store" required></v-text-field>
              </v-col>

              <v-col cols="12" sm="6">
                <v-text-field v-model="itemEdited.address" label="רחוב" filled rounded dense
                  :rules="[v => !!v || 'שדה חובה']" prepend-inner-icon="mdi-map-marker" required></v-text-field>
              </v-col>

              <v-col cols="12" sm="4">
                <v-combobox :items="citiesItems" v-model="itemEdited.city_web" label="עיר" filled rounded dense
                  :rules="[v => !!v || 'שדה חובה']" prepend-inner-icon="mdi-city" return-object required></v-combobox>
              </v-col>

              <v-col cols="12" sm="4">
                <v-combobox :items="countriesItems" v-model="itemEdited.country_web" label="מדינה (רק בחול)" filled
                  rounded dense prepend-inner-icon="mdi-earth" return-object></v-combobox>
              </v-col>

              <v-col cols="12" sm="4">
                <v-autocomplete :items="zonesItems" v-model="itemEdited.zone_web" label="אזור" filled rounded dense
                  :rules="[v => !!v || 'שדה חובה']" prepend-inner-icon="mdi-map" required></v-autocomplete>
              </v-col>

              <v-col cols="12" sm="6">
                <v-text-field v-model="itemEdited.website" label="אתר" filled rounded dense
                  prepend-inner-icon="mdi-web"></v-text-field>
              </v-col>

              <v-col cols="12" sm="6">
                <v-text-field v-model="itemEdited.phone" @input="filterNumeric" label="טלפון" filled rounded dense
                  prepend-inner-icon="mdi-phone"></v-text-field>
              </v-col>

              <!-- Categories Section -->
              <v-col cols="12">
                <div class="section-title primary-color">קטגוריות</div>
              </v-col>

              <v-col cols="12" sm="4">
                <v-autocomplete :items="categoriesItems" v-model="itemEdited.category_web" label="קטגוריה" filled
                  rounded dense :rules="[v => !!v || 'שדה חובה']" prepend-inner-icon="mdi-tag"
                  required></v-autocomplete>
              </v-col>

              <v-col cols="12" sm="4">
                <v-autocomplete :items="subCategoriesItems" multiple v-model="itemEdited.sub_category_web"
                  label="תת קטגוריה" filled rounded dense prepend-inner-icon="mdi-tag-multiple" chips
                  small-chips></v-autocomplete>
              </v-col>

              <v-col cols="12" sm="4">
                <v-select :items="typesItems" multiple v-model="itemEdited.type_web" label="סוג" filled rounded dense
                  :rules="[v => !!v || 'שדה חובה']" prepend-inner-icon="mdi-format-list-bulleted-type" chips small-chips
                  required></v-select>
              </v-col>

              <v-col cols="12">
                <v-textarea v-model="itemEdited.details" label="מידע על המסעדה" filled rounded dense auto-grow rows="2"
                  prepend-inner-icon="mdi-information-outline"></v-textarea>
              </v-col>

              <!-- Kashrut Section -->
              <v-col cols="12">
                <div class="section-title primary-color">כשרות</div>
              </v-col>

              <v-col cols="12" sm="4">
                <v-autocomplete :items="kashrutItems" v-model="itemEdited.kashrut.kashrut_name_web" label="כשרות" filled
                  rounded dense :rules="[v => !!v || 'שדה חובה']" prepend-inner-icon="mdi-check-circle"
                  required></v-autocomplete>
              </v-col>

              <v-col cols="12" sm="4" v-if="itemEdited.kashrut.kashrut_name_web === 'badatz_local'">
                <v-text-field v-model="itemEdited.kashrut.local_kashrut_name" label="כשרות מקומית" filled rounded dense
                  prepend-inner-icon="mdi-check-decagram"></v-text-field>
              </v-col>

              <v-col cols="12" sm="4">
                <v-text-field v-model="itemEdited.kashrut.kashrut_details" label="פרטים נוספים על הכשרות" filled rounded
                  dense prepend-inner-icon="mdi-information"></v-text-field>
              </v-col>

              <!-- Opening Hours Section -->
              <v-col cols="12">
                <div class="section-title primary-color">שעות פתיחה</div>
              </v-col>

              <v-col cols="12">
                <v-row>
                  <v-col cols="12" sm="6" md="3" lg="3" xl="3" class="pa-2">
                    <v-card class="pa-3 rounded-card" outlined>
                      <div class="text-subtitle-1 font-weight-medium mb-2">יום א'</div>
                      <v-row dense>
                        <v-col cols="6">
                          <v-text-field v-model="itemEdited.open_hours.sunday.open" type="time" label="פתיחה" filled
                            rounded dense></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field v-model="itemEdited.open_hours.sunday.close" type="time" label="סגירה" filled
                            rounded dense></v-text-field>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>

                  <v-col cols="12" sm="6" md="3" lg="3" xl="3" class="pa-2">
                    <v-card class="pa-3 rounded-card" outlined>
                      <div class="text-subtitle-1 font-weight-medium mb-2">יום ב'</div>
                      <v-row dense>
                        <v-col cols="6">
                          <v-text-field v-model="itemEdited.open_hours.monday.open" type="time" label="פתיחה" filled
                            rounded dense></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field v-model="itemEdited.open_hours.monday.close" type="time" label="סגירה" filled
                            rounded dense></v-text-field>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>

                  <v-col cols="12" sm="6" md="3" lg="3" xl="3" class="pa-2">
                    <v-card class="pa-3 rounded-card" outlined>
                      <div class="text-subtitle-1 font-weight-medium mb-2">יום ג'</div>
                      <v-row dense>
                        <v-col cols="6">
                          <v-text-field v-model="itemEdited.open_hours.tuesday.open" type="time" label="פתיחה" filled
                            rounded dense></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field v-model="itemEdited.open_hours.tuesday.close" type="time" label="סגירה" filled
                            rounded dense></v-text-field>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>

                  <v-col cols="12" sm="6" md="3" lg="3" xl="3" class="pa-2">
                    <v-card class="pa-3 rounded-card" outlined>
                      <div class="text-subtitle-1 font-weight-medium mb-2">יום ד'</div>
                      <v-row dense>
                        <v-col cols="6">
                          <v-text-field v-model="itemEdited.open_hours.wednesday.open" type="time" label="פתיחה" filled
                            rounded dense></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field v-model="itemEdited.open_hours.wednesday.close" type="time" label="סגירה" filled
                            rounded dense></v-text-field>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>

                  <v-col cols="12" sm="6" md="3" lg="3" xl="3" class="pa-2">
                    <v-card class="pa-3 rounded-card" outlined>
                      <div class="text-subtitle-1 font-weight-medium mb-2">יום ה'</div>
                      <v-row dense>
                        <v-col cols="6">
                          <v-text-field v-model="itemEdited.open_hours.thursday.open" type="time" label="פתיחה" filled
                            rounded dense></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field v-model="itemEdited.open_hours.thursday.close" type="time" label="סגירה" filled
                            rounded dense></v-text-field>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>

                  <v-col cols="12" sm="6" md="3" lg="3" xl="3" class="pa-2">
                    <v-card class="pa-3 rounded-card" outlined>
                      <div class="text-subtitle-1 font-weight-medium mb-2">יום ו'</div>
                      <v-row dense>
                        <v-col cols="6">
                          <v-text-field v-model="itemEdited.open_hours.friday.open" type="time" label="פתיחה" filled
                            rounded dense></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field v-model="itemEdited.open_hours.friday.close" type="time" label="סגירה" filled
                            rounded dense></v-text-field>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>

                  <v-col cols="12" sm="6" md="3" lg="3" xl="3" class="pa-2">
                    <v-card class="pa-3 rounded-card" outlined>
                      <div class="text-subtitle-1 font-weight-medium mb-2">מוצ"ש</div>
                      <v-row dense>
                        <v-col cols="6">
                          <v-text-field v-model="itemEdited.open_hours.saturday.open" type="time" label="פתיחה" filled
                            rounded dense></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field v-model="itemEdited.open_hours.saturday.close" type="time" label="סגירה" filled
                            rounded dense></v-text-field>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12">
                <v-row>
                  <v-col cols="auto">
                    <v-checkbox v-model="itemEdited.open_hours.only_weekend" :label="weekendTitle" hide-details
                      class="mt-0" color="#062a4e"></v-checkbox>
                  </v-col>
                  <v-col cols="auto">
                    <v-checkbox v-model="itemEdited.open_hours.isTemporarilyClosed" :label="tempClosedTitle"
                      hide-details class="mt-0" color="#062a4e"></v-checkbox>
                  </v-col>
                </v-row>
              </v-col>

              <!-- Location Section -->
              <v-col cols="12">
                <div class="section-title primary-color">מיקום</div>
              </v-col>

              <v-col cols="12" sm="6">
                <v-text-field v-model="itemEdited.location.latitude" label="קו רוחב (Latitude)" filled rounded dense
                  :rules="[v => !!v || 'שדה חובה']" prepend-inner-icon="mdi-latitude" required></v-text-field>
              </v-col>

              <v-col cols="12" sm="6">
                <v-text-field v-model="itemEdited.location.longitude" label="קו אורך (Longitude)" filled rounded dense
                  :rules="[v => !!v || 'שדה חובה']" prepend-inner-icon="mdi-longitude" required></v-text-field>
              </v-col>

              <v-col cols="12" class="d-flex flex-wrap align-center">
                <v-btn @click="chooseDeviceLocation()" color="#062a4e" dark class="mr-3 mb-3" rounded>
                  <v-icon left>mdi-crosshairs-gps</v-icon>
                  בחר את מיקום המכשיר
                </v-btn>

                <div class="text-body-2 grey--text text--darken-1 mb-3">
                  <v-icon small color="grey darken-1">mdi-information-outline</v-icon>
                  או בחר מיקום מהמפה
                </div>
              </v-col>

              <v-col cols="12">
                <!-- Search Field -->
                <div class="map-search-input">
                  <GmapPlaceInput @place_changed="setPlace" ref="gmapInput" class="elevation-1 rounded-lg" />
                </div>

                <!-- Google Maps -->
                <div class="map-container">
                  <GmapMap ref="gmapRef" :center="center" :zoom="12" style="width: 100%; height: 400px"
                    @click="mapClicked">
                    <GmapMarker v-if="marker.position.lat && marker.position.lng" :position="marker.position"
                      @click="markerClicked"></GmapMarker>
                  </GmapMap>
                </div>
              </v-col>

              <!-- Images Section -->
              <v-col cols="12">
                <div class="section-title primary-color">העלאת תמונות</div>
              </v-col>

              <v-col cols="12" sm="6" md="3">
                <div v-if="!itemEdited.logo" class="image-upload-container">
                  <v-file-input label="לוגו" filled v-model="itemEdited.logo" @change="handleFileUpload('logo', null)"
                    accept="image/*" prepend-icon="" prepend-inner-icon="mdi-camera" hide-details
                    class="mb-0"></v-file-input>
                </div>
                <div v-else class="image-preview-container">
                  <v-icon class="delete-icon" @click="handleDelete('logo', null)">
                    mdi-delete
                  </v-icon>
                  <div class="text-caption primary-color pa-1">לוגו</div>
                  <div class="image-wrapper">
                    <img class="image-preview" :src="host + '/logo' + itemEdited.logo" alt="Preview" />
                  </div>
                </div>
              </v-col>

              <v-col cols="12" sm="6" md="3">
                <div v-if="!itemEdited.kashrut.certificate" class="image-upload-container">
                  <v-file-input label="תעודת כשרות" filled v-model="itemEdited.kashrut.certificate"
                    @change="handleFileUpload('certificate', null)" accept="image/*" prepend-icon=""
                    prepend-inner-icon="mdi-camera" hide-details class="mb-0"></v-file-input>
                </div>
                <div v-else class="image-preview-container">
                  <v-icon class="delete-icon" @click="handleDelete('certificate', null)">
                    mdi-delete
                  </v-icon>
                  <div class="text-caption primary-color pa-1">תעודת כשרות</div>
                  <div class="image-wrapper">
                    <img class="image-preview" :src="host + '/certificates' + itemEdited.kashrut.certificate"
                      alt="Preview" />
                  </div>
                </div>
              </v-col>

              <v-col v-for="(picture, index) in arrayPictures" :key="index" cols="12" sm="6" md="3">
                <div v-if="!itemEdited.pictures[index]" class="image-upload-container">
                  <v-file-input :label="'תמונה ' + (index + 1)" filled v-model="itemEdited.pictures[index]"
                    @change="handleFileUpload('picture', index)" accept="image/*" prepend-icon=""
                    prepend-inner-icon="mdi-camera" hide-details class="mb-0"></v-file-input>
                </div>
                <div v-else class="image-preview-container">
                  <v-icon class="delete-icon" @click="handleDelete('picture', index)">
                    mdi-delete
                  </v-icon>
                  <div class="text-caption primary-color pa-1">תמונה {{ (index + 1) }}</div>
                  <div class="image-wrapper">
                    <img v-if="itemEdited.pictures[index]" class="image-preview"
                      :src="host + '/pictures' + itemEdited.pictures[index]" alt="Preview" />
                  </div>
                </div>
              </v-col>

              <v-col cols="12" class="text-center mt-4">
                <div class="d-flex justify-center">
                  <v-btn color="#062a4e" dark @click="editRestaurant()" rounded x-large min-width="180" class="mx-2"
                    :loading="progressShow">
                    <v-icon left>mdi-content-save</v-icon>
                    שמור שינויים
                  </v-btn>

                  <v-btn color="#f44336" dark @click="openDeleteDialog()" rounded x-large min-width="180" class="mx-2">
                    <v-icon left>mdi-delete</v-icon>
                    מחק מסעדה
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-form>

          <v-row v-if="progressShow" justify="center" align="center" class="my-8">
            <v-progress-circular indeterminate color="#3F51B5" size="64"></v-progress-circular>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>

    <AlertDialog v-model="alertDialog" v-if="alertDialog" :item="itemToDelete" @itemDeleted="itemDeleted" />
    <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>
  </div>
</template>

<script>
import ApiServices from '@/services/api.service'
import SnackBar from '@/components/widgets/snackBar'
import Constants from '@/util/GetConstant'
import axios from 'axios'
import AlertDialog from '@/components/admin/editRest/AlertDialog'

export default {
  components: {
    SnackBar,
    AlertDialog,
  },
  data() {
    return {
      alertDialog: false,
      progressShow: false,
      snackbar: false,
      snackbarColorBt: "green",
      snacbarText: "",
      itemToDelete: {},
      itemEdited: {
        name: "",
        address: "",
        city: "",
        city_web: { value: "", text: "" },
        country: "",
        country_web: { value: "", text: "" },
        zone: "",
        zone_web: "",
        type: [],
        type_web: [],
        phone: "",
        website: "",
        category: "",
        category_web: "",
        sub_category: [],
        sub_category_web: [],
        details: "",
        pictures: [],
        logo: null,
        kashrut: { kashrut_name: "", kashrut_name_web: "", local_kashrut_name: "", kashrut_details: "", certificate: null },
        open_hours: {
          sunday: { open: "", close: "" }, monday: { open: "", close: "" }, tuesday: { open: "", close: "" },
          wednesday: { open: "", close: "" }, thursday: { open: "", close: "" }, friday: { open: "", close: "" }, saturday: { open: "", close: "" },
          only_weekend: false,
          isTemporarilyClosed: false
        },
        location: { latitude: "", longitude: "" }
      },
      objKashruyot: {},
      objTypes: {},
      objCategories: {},
      objSubCategories: {},
      objZones: {},
      objCountries: {},
      objCities: {},
      arrayNames: [],
      autoCompleteList: [],
      searchSelected: "",
      idSelected: "",
      arrayPictures: ["", "", "", ""],
      host: "",
      weekendTitle: "פתוח רק בסופ\"ש",
      tempClosedTitle: "סגור זמנית",
      center: { lat: 32.0879976, lng: 34.8384455 },
      marker: {
        position: { lat: 32.0879976, lng: 34.8384455 }
      }
    }
  },
  methods: {
    async editRestaurant() {
      try {
        this.progressShow = true;
        let token = localStorage.getItem("token");
        console.log(this.itemEdited.city_web);

        this.itemEdited.city_web = this.itemEdited.city_web.value;
        this.itemEdited.country_web = this.itemEdited.country_web ? this.itemEdited.country_web.value : "";
        const restaurantJSON = JSON.stringify({ rest: this.itemEdited })
        let api = process.env.VUE_APP_BASE_URL + `/admin/edit_restaurant`;
        const res = await fetch(api, ApiServices.requestOptions("POST", restaurantJSON, token));
        this.progressShow = false;
        const jsonObject = await res.json();
        if (res.status >= 400) {
          this.showSnackBar("שגיאה בעריכת מסעדה : " + jsonObject.message, "red");
        } else if (res.status === 200) {
          this.showSnackBar("המסעדה נערכה בהצלחה!", "green");
        }
      } catch (error) {
        this.progressShow = false;
        this.showSnackBar("Error save new restaurant: " + error, "red");
      }
    },
    openDeleteDialog() {
      this.itemToDelete = this.itemEdited;
      this.alertDialog = true;
    },
    async getRestDetails(by) {
      console.log(111111);

      try {
        this.progressShow = true;
        let nameSearchJSON;
        if (by === "by_id") {
          nameSearchJSON = JSON.stringify({ idSelected: this.idSelected });
        } else {
          nameSearchJSON = JSON.stringify({ searchSelected: this.searchSelected });
        }
        const token = localStorage.getItem("token");
        let api = process.env.VUE_APP_BASE_URL + `/admin/get_rest_for_edit`;

        const res = await fetch(api, ApiServices.requestOptions("POST", nameSearchJSON, token));
        const jsonObject = await res.json();
        if (res.status >= 400) {
          this.showSnackBar("שגיאה בקבלת נתונים: " + jsonObject.message, "red");
          this.progressShow = false;
        } else if (res.status === 200) {
          this.itemEdited = jsonObject;
          this.progressShow = false;

          // Find matching city object
          const selectedCity = this.citiesItems.find(city => city.value === jsonObject.city_web);
          if (selectedCity) {
            this.itemEdited.city_web = selectedCity;
          }
          const selectedCountry = this.countriesItems.find(country => country.value === jsonObject.country_web);
          if (selectedCountry) {
            this.itemEdited.country_web = selectedCountry;
          }
          console.log(66666);
          console.log(selectedCity);
          console.log(this.itemEdited.city_web);

          this.updateMarkerPosition(jsonObject);
        }
      } catch (error) {
        this.progressShow = false;
        this.showSnackBar("Error get restaurants list: " + error, "red");
      }
    },
    updateMarkerPosition(item) {
      const latitude = parseFloat(item.location.latitude);
      const longitude = parseFloat(item.location.longitude);

      // Ensure the latitude and longitude are valid numbers
      if (isFinite(latitude) && isFinite(longitude)) {
        // Update marker position with valid latitude and longitude
        this.marker.position = {
          lat: latitude,
          lng: longitude
        };

        // Optionally, move the map to center on the new marker position
        this.$refs.gmapRef.$mapObject.panTo(this.marker.position);
      } else {
        console.error("Invalid latitude or longitude values:", latitude, longitude);
      }
    },
    chooseDeviceLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          position => {
            this.showPosition(position);
            { true }
          },
          error => {
            this.showSnackBar("שגיאה בקבלת המיקום: יש להפעיל את המיקום בלחיצה על האייקון למעלה בשורת הכתובת", "red");
          }
        );
      } else {
        this.showSnackBar("Geolocation is not supported", "red");
      }
    },
    showPosition(position) {
      this.itemEdited.location.latitude = position.coords.latitude;
      this.itemEdited.location.longitude = position.coords.longitude;

      // Update marker position
      this.marker.position = {
        lat: position.coords.latitude,
        lng: position.coords.longitude
      };

      // Update map center
      this.center = this.marker.position;
    },
    showSnackBar(message, color) {
      this.snacbarText = message;
      this.snackbarColorBt = color;
      this.snackbar = true
    },
    async getConstants() {
      const constants = await Constants.getConstant();
      if (!constants) {
        this.showSnackBar("שגיאה: יותר מדי כניסות מהכתובת הזאת, נסה שוב מאוחר יותר", "red");
      }
      this.objKashruyot = constants.kashruyot
      this.objTypes = constants.types;
      this.objCategories = constants.categories;
      this.objSubCategories = constants.sub_categories;
      this.objZones = constants.zones
      this.objCountries = constants.countries
      this.objCities = constants.cities
      console.log(555555);
      console.log(this.objCities);

    },
    async getAutoComplete() {
      const constants = await Constants.getSearchAutoCompleteList();
      if (!constants) {
        this.showSnackBar("שגיאה: יותר מדי כניסות מהכתובת הזאת, נסה שוב מאוחר יותר", "red");
      }
      this.arrayNames = constants.map(constant => constant.name);
    },
    handleFileUpload(type, index) {
      if (type === "certificate") {
        if (this.itemEdited.kashrut.certificate) {
          this.uploadOrDeleteImage("upload", "certificate", null, this.itemEdited.kashrut.certificate);
        }
      } else if (type === "logo") {
        if (this.itemEdited.logo) {
          this.uploadOrDeleteImage("upload", "logo", null, this.itemEdited.logo);
        }
      } else if (type === "picture") {
        if (this.itemEdited.pictures[index]) {
          this.uploadOrDeleteImage("upload", "picture", index, this.itemEdited.pictures[index]);
        }
      }
    },
    handleDelete(type, index) {
      if (type === "certificate") {
        this.uploadOrDeleteImage("delete", "certificate", null, null);
        this.itemEdited.kashrut.certificate = null;
      } else if (type === "logo") {
        this.uploadOrDeleteImage("delete", "logo", null, null);
        this.itemEdited.logo = null;
      } else if (type === "picture") {
        this.uploadOrDeleteImage("delete", "picture", index, null);
        this.itemEdited.pictures[index] = null;
      }
    },
    async uploadOrDeleteImage(action, type, index, image) {
      let message = "הקובץ נשמר בהצלחה!"
      if (action === "delete") {
        message = "הקובץ נמחק בהצלחה!"
      }
      if (!this.itemEdited._id) {
        return this.showSnackBar('יש לבחור מסעדה לפני העלאת קובץ', 'red');
      }
      try {
        this.progressShow = true;
        const token = localStorage.getItem('token');
        let api = process.env.VUE_APP_BASE_URL + `/admin/handle_image`;
        const formData = new FormData();
        formData.append('image', image);
        formData.append('action', action);
        formData.append('type', type);
        formData.append('index', index);
        formData.append('restId', this.itemEdited._id);
        const response = await axios.post(api, formData, {
          headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'multipart/form-data'
          }
        });
        if (action === 'upload') {
          if (type === "logo") {
            this.itemEdited.logo = response.data.newLink
          }
          if (type === "certificate") {
            this.itemEdited.kashrut.certificate = response.data.newLink
          }
          if (type === "picture") {
            this.itemEdited.pictures[index] = response.data.newLink
          }
        }
        this.showSnackBar(message, 'green');
        this.progressShow = false;
      } catch (error) {
        this.showSnackBar(' שגיאה בשמירת הקובץ' + error, 'red');
        this.progressShow = false;
      }
    },
    setPlace(place) {
      this.marker.position = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng()
      };
      this.center = this.marker.position;

      // Update location in itemEdited
      this.itemEdited.location.latitude = place.geometry.location.lat();
      this.itemEdited.location.longitude = place.geometry.location.lng();
    },
    itemDeleted(text, color) {
      this.showSnackBar(text, color)
    },
    markerClicked(event) {
      // Instead of alert, show a snackbar with the coordinates
      this.showSnackBar(`מיקום נבחר: ${event.latLng.lat().toFixed(6)}, ${event.latLng.lng().toFixed(6)}`, "blue");
    },
    mapClicked(event) {
      this.marker.position = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng()
      };

      this.itemEdited.location.latitude = event.latLng.lat();
      this.itemEdited.location.longitude = event.latLng.lng();
    },
    filterNumeric(value) {
      console.log(34234334);

      // Replace non-numeric characters with an empty string
      this.itemEdited.phone = value.replace(/\D+/g, '');
    },
    async onMontedInitialize() {
      await this.getConstants();
      this.getAutoComplete();
      this.host = process.env.VUE_APP_BASE_URL;

      if (this.$route.params._id) {
        this.idSelected = this.$route.params._id
        this.getRestDetails('by_id');
      }
      // change maps input style
      // this.$nextTick(() => {
      //   const input = this.$refs.gmapInput.$el.querySelector('input');
      //   if (input) {
      //     input.style.backgroundColor = '#f5f7fa';
      //     input.style.padding = '12px 16px';
      //     input.style.border = '1px solid #dae1eb';
      //     input.style.borderRadius = '8px';
      //     input.style.width = '100%';
      //     input.style.maxWidth = '400px';
      //     input.style.fontSize = '14px';
      //   }
      // });
    },
  },
  computed: {
    kashrutItems() {
      return Object.entries(this.objKashruyot).map(([key, kashrut]) => ({
        value: key, // You can use the key as the value
        text: kashrut[this.$i18n.locale] || kashrut.en // or value.en for English
      }));
    },
    zonesItems() {
      return Object.entries(this.objZones).map(([key, zone]) => ({
        value: key, // You can use the key as the value
        text: zone[this.$i18n.locale] || zone.en // or value.en for English
      }));
    },
    typesItems() {
      return Object.entries(this.objTypes).map(([key, type]) => ({
        value: key, // You can use the key as the value
        text: type[this.$i18n.locale] || type.en // or value.en for English
      }));
    },
    categoriesItems() {
      return Object.entries(this.objCategories).map(([key, category]) => ({
        value: key, // You can use the key as the value
        text: category[this.$i18n.locale] || category.en // or value.en for English
      }));
    },
    subCategoriesItems() {
      return Object.entries(this.objSubCategories).map(([key, subCategory]) => ({
        value: key, // You can use the key as the value
        text: subCategory[this.$i18n.locale] || subCategory.en // or value.en for English
      }));
    },
    countriesItems() {
      return Object.entries(this.objCountries).map(([key, country]) => ({
        value: key, // You can use the key as the value
        text: country[this.$i18n.locale] || country.en // or value.en for English
      }));
    },
    citiesItems() {
      return Object.entries(this.objCities).map(([key, city]) => ({
        value: key, // Use the city key as the value
        text: city[this.$i18n.locale] || city.en // Use localized city name
      }));
    }
  },
  mounted() {
     this.onMontedInitialize();
  },
  watch: {
    searchSelected() {
      if (this.searchSelected) {
        this.getRestDetails("by_name")
      }
    },
    'itemEdited.location.latitude': function (newLat) {
      this.updateMarkerPosition(this.itemEdited);
    },
    'itemEdited.location.longitude': function (newLng) {
      this.updateMarkerPosition(this.itemEdited);
    }
  }
};
</script>

<style scoped>
.edit-restaurant-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 16px 64px;
}

/* Override Vuetify styles for better RTL support */
:deep(.v-text-field__slot) {
  direction: rtl;
}

:deep(.v-label) {
  right: 12px;
  left: auto;
}

:deep(.v-input__prepend-inner) {
  margin-right: 0;
  margin-left: 8px;
}

:deep(.v-text-field--filled .v-label) {
  right: 12px;
  left: auto;
}

:deep(.v-text-field--filled .v-input__prepend-inner) {
  margin-right: 12px;
  margin-left: 0;
}

:deep(.v-select__selections) {
  padding-right: 12px;
}

:deep(.v-chip) {
  margin: 2px;
}

:deep(.v-messages) {
  text-align: right;
}

.image-upload-container {
  border: 2px dashed #dae1eb;
  border-radius: 8px;
  background-color: #f5f7fa;
  padding: 16px;
  text-align: center;
  transition: all 0.3s ease;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.image-upload-container:hover {
  border-color: #3f51b5;
  background-color: #f0f3f8;
}

.image-preview-container {
  position: relative;
  width: 100%;
  height: 150px;
  background-color: #f5f7fa;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid #dae1eb;
}

.image-wrapper {
  width: 100%;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.image-preview {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.delete-icon {
  position: absolute;
  top: 8px;
  right: 8px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  padding: 4px;
  cursor: pointer;
  z-index: 10;
  color: #f44336;
}

.map-container {
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  margin-top: 16px;
}

.map-search-input {
  margin-bottom: 16px;
  width: 100%;
  max-width: 400px;
}

.section-title {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #dae1eb;
}
</style>