<template>
    <v-row justify="center">
        <v-dialog v-model="openMode" fullscreen hide-overlay transition="dialog-bottom-transition">
            <v-card class="filter-dialog">
                <!-- Toolbar -->
                <v-toolbar  elevation="2" class="primary-gradient">
                    <v-btn  icon  @click="openMode = false" class="mr-4"
                    >
                        <v-icon dark
                        :small="$vuetify.breakpoint.smAndDown"
                        >mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title class="text-h7 font-weight-medium">
                        {{$t('filterDialog.filter_title')}}
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn 
                        text 
                        @click="ressetChoosed()" 
                        class="reset-btn mr-2"
                        :disabled="!hasActiveFilters"
                        :small="$vuetify.breakpoint.smAndDown"
                    >
                        <v-icon left>mdi-refresh</v-icon>
                        {{$t('filterDialog.reset')}}
                    </v-btn>
                    <v-btn 
                        color="success" 
                        @click="searchFiltered()" 
                        class="apply-btn"
                        :disabled="!hasActiveFilters"
                        :small="$vuetify.breakpoint.smAndDown"
                    >
                        <v-icon left>mdi-check</v-icon>
                        {{$t('filterDialog.filter_and_search')}}
                    </v-btn>
                </v-toolbar>

                <!-- Filter Sections -->
                <v-container class="pa-6">
                    <v-expansion-panels 
                        accordion 
                        focusable 
                        multiple 
                        class="filter-panels"
                    >
                        <!-- Kashrut Section -->
                        <v-expansion-panel class="mb-4 rounded-lg">
                            <v-expansion-panel-header class="subtitle-1 font-weight-medium">
                                <v-row no-gutters align="center">
                                    <v-icon color="primary" class="mr-2">mdi-star-david</v-icon>
                                    {{$t('filterDialog.kashrut')}}
                                    <v-spacer></v-spacer>
                                    <v-chip
                                        x-small
                                        v-if="getSelectedCount('kashruyot')"
                                        color="primary"
                                        class="ml-2"
                                    >
                                        {{ getSelectedCount('kashruyot') }}
                                    </v-chip>
                                </v-row>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <div v-if="!hasSelectedItems('kashruyot')" class="py-2 grey--text">
                                    <v-icon small class="mr-1">mdi-information</v-icon>
                                    {{$t('filterDialog.show_all')}}
                                </div>
                                <v-row class="mt-2">
                                    <v-col 
                                        v-for="(label, key) in constants.kashruyot"
                                        :key="key"
                                        cols="12"
                                        sm="6"
                                        md="4"
                                    >
                                        <v-checkbox
                                            v-model="constantsChoosed.kashruyot[key]"
                                            :label="label[lang]"
                                            :value="label"
                                            color="primary"
                                            hide-details
                                            class="my-1"
                                            dense
                                        >
                                            <template v-slot:label>
                                                <div class="d-flex align-center">
                                                    {{ label[lang] }}
                                                </div>
                                            </template>
                                        </v-checkbox>
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-content>
                        </v-expansion-panel>

                        <!-- Type Section -->
                        <v-expansion-panel class="mb-4 rounded-lg">
                            <v-expansion-panel-header class="subtitle-1 font-weight-medium">
                                <v-row no-gutters align="center">
                                    <v-icon color="primary" class="mr-2">mdi-silverware-fork-knife</v-icon>
                                    {{$t('filterDialog.type')}}
                                    <v-spacer></v-spacer>
                                    <v-chip
                                        x-small
                                        v-if="getSelectedCount('types')"
                                        color="primary"
                                        class="ml-2"
                                    >
                                        {{ getSelectedCount('types') }}
                                    </v-chip>
                                </v-row>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <div v-if="!hasSelectedItems('types')" class="py-2 grey--text">
                                    <v-icon small class="mr-1">mdi-information</v-icon>
                                    {{$t('filterDialog.show_all')}}
                                </div>
                                <v-row class="mt-2">
                                    <v-col 
                                        v-for="(label, key) in constants.types"
                                        :key="key"
                                        cols="12"
                                        sm="6"
                                        md="4"
                                    >
                                        <v-checkbox
                                            v-model="constantsChoosed.types[key]"
                                            :label="label[lang]"
                                            :value="label"
                                            color="primary"
                                            hide-details
                                            class="my-1"
                                            dense
                                        >
                                            <template v-slot:label>
                                                <div class="d-flex align-center">
                                                    {{ label[lang] }}
                                                </div>
                                            </template>
                                        </v-checkbox>
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-content>
                        </v-expansion-panel>

                        <!-- Category Section -->
                        <v-expansion-panel class="mb-4 rounded-lg">
                            <v-expansion-panel-header class="subtitle-1 font-weight-medium">
                                <v-row no-gutters align="center">
                                    <v-icon color="primary" class="mr-2">mdi-shape</v-icon>
                                    {{$t('filterDialog.category')}}
                                    <v-spacer></v-spacer>
                                    <v-chip
                                        x-small
                                        v-if="getSelectedCount('categories')"
                                        color="primary"
                                        class="ml-2"
                                    >
                                        {{ getSelectedCount('categories') }}
                                    </v-chip>
                                </v-row>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <div v-if="!hasSelectedItems('categories')" class="py-2 grey--text">
                                    <v-icon small class="mr-1">mdi-information</v-icon>
                                    {{$t('filterDialog.show_all')}}
                                </div>
                                <v-row class="mt-2">
                                    <v-col 
                                        v-for="(label, key) in constants.categories"
                                        :key="key"
                                        cols="12"
                                        sm="6"
                                        md="4"
                                    >
                                        <v-checkbox
                                            v-model="constantsChoosed.categories[key]"
                                            :label="label[lang]"
                                            :value="label"
                                            color="primary"
                                            hide-details
                                            class="my-1"
                                            dense
                                        >
                                            <template v-slot:label>
                                                <div class="d-flex align-center">
                                                    {{ label[lang] }}
                                                </div>
                                            </template>
                                        </v-checkbox>
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-content>
                        </v-expansion-panel>

                        <!-- Sub Category Section -->
                        <v-expansion-panel class="mb-4 rounded-lg">
                            <v-expansion-panel-header class="subtitle-1 font-weight-medium">
                                <v-row no-gutters align="center">
                                    <v-icon color="primary" class="mr-2">mdi-shape-outline</v-icon>
                                    {{$t('filterDialog.sub_category')}}
                                    <v-spacer></v-spacer>
                                    <v-chip
                                        x-small
                                        v-if="getSelectedCount('sub_categories')"
                                        color="primary"
                                        class="ml-2"
                                    >
                                        {{ getSelectedCount('sub_categories') }}
                                    </v-chip>
                                </v-row>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <div v-if="!hasSelectedItems('sub_categories')" class="py-2 grey--text">
                                    <v-icon small class="mr-1">mdi-information</v-icon>
                                    {{$t('filterDialog.show_all')}}
                                </div>
                                <v-row class="mt-2">
                                    <v-col 
                                        v-for="(label, key) in constants.sub_categories"
                                        :key="key"
                                        cols="12"
                                        sm="6"
                                        md="4"
                                    >
                                        <v-checkbox
                                            v-model="constantsChoosed.sub_categories[key]"
                                            :label="label[lang]"
                                            :value="label"
                                            color="primary"
                                            hide-details
                                            class="my-1"
                                            dense
                                        >
                                            <template v-slot:label>
                                                <div class="d-flex align-center">
                                                    {{ label[lang] }}
                                                </div>
                                            </template>
                                        </v-checkbox>
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-container>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
export default {
    props: {
        constantsChoosed: Object,
        lang: String,
        constants: Object,
        value: { type: Boolean, default: false },
    },
    
    methods: {
        searchFiltered() {
            this.$emit('searchFiltered')
            this.openMode = false;
        },
        
        ressetChoosed() {
            this.$emit('ressetChoosed')
        },
        
        hasSelectedItems(category) {
            return Object.values(this.constantsChoosed[category]).some(item => item)
        },
        
        getSelectedCount(category) {
            return Object.values(this.constantsChoosed[category]).filter(item => item).length
        }
    },
    
    computed: {
        openMode: {
            get() {
                return this.value
            },
            set(v) {
                return this.$emit('input', v)
            }
        },
        
        hasActiveFilters() {
            return ['kashruyot', 'types', 'categories', 'sub_categories'].some(category => 
                this.hasSelectedItems(category)
            )
        }
    }
}
</script>

<style scoped>
.filter-dialog {
    background-color: #f5f7fa;
}



.filter-panels {
    max-width: 1200px;
    margin: 0 auto;
}

.v-expansion-panel {
    background-color: white !important;
    box-shadow: 0 2px 4px rgba(0,0,0,0.05) !important;
}

.v-expansion-panel::before {
    box-shadow: none !important;
}

.v-expansion-panel-header {
    padding: 16px 24px;
}

.primary-gradient {
    background: linear-gradient(45deg, var(--v-primary-darken1), var(--v-primary-base)) !important;
}

.reset-btn {
    color: rgba(0, 0, 0, 0.8) !important;
    background-color: rgba(255, 255, 255, 0.2) !important; /* Light background for contrast */
    border-radius: 8px;
    padding: 4px 12px;
}

.apply-btn {
    color: white !important;
    font-weight: 500;
}



.v-checkbox {
    margin-top: 4px !important;
    margin-bottom: 4px !important;
}

.v-input--selection-controls {
    margin-top: 0;
    padding-top: 0;
}
</style>
