<template>
  <div class="edit-restaurant-container">
    <v-card class="rounded-card card-shadow" elevation="0">
      <v-card-title class="d-flex justify-center pt-6 pb-2">
        <h1 class="text-h4 primary-color font-weight-bold">עריכת מסעדה</h1>
      </v-card-title>

      <v-card-text class="form-container">
        <v-container>
          <v-row>
            <!-- Basic Information Section -->
            <v-col cols="12">
              <div class="section-title primary-color">מידע בסיסי</div>
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field v-model="itemEdited.name" label="שם המסעדה" filled rounded dense
                :rules="[v => !!v || 'שדה חובה']" prepend-inner-icon="mdi-store"></v-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field v-model="itemEdited.address" label="רחוב" filled rounded dense
                prepend-inner-icon="mdi-map-marker"></v-text-field>
            </v-col>

            <v-col cols="12" sm="4">
              <v-combobox :items="citiesItems" v-model="itemEdited.city_web" label="עיר" filled rounded dense
                return-object prepend-inner-icon="mdi-city"></v-combobox>
            </v-col>

            <v-col cols="12" sm="4">
              <v-combobox :items="countriesItems" v-model="itemEdited.country_web" label="מדינה (רק בחול)" filled return-object
                rounded dense prepend-inner-icon="mdi-earth"></v-combobox>
            </v-col>

            <v-col cols="12" sm="4">
              <v-autocomplete :items="zonesItems" v-model="itemEdited.zone_web" label="אזור" filled rounded dense
                prepend-inner-icon="mdi-map"></v-autocomplete>
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field v-model="itemEdited.website" label="אתר" filled rounded dense
                prepend-inner-icon="mdi-web"></v-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field v-model="itemEdited.phone" @input="filterNumeric" label="טלפון" filled rounded dense
                prepend-inner-icon="mdi-phone"></v-text-field>
            </v-col>

            <!-- Categories Section -->
            <v-col cols="12">
              <div class="section-title primary-color">קטגוריות</div>
            </v-col>

            <v-col cols="12" sm="4">
              <v-autocomplete :items="categoriesItems" v-model="itemEdited.category_web" label="קטגוריה" filled rounded
                dense prepend-inner-icon="mdi-tag"></v-autocomplete>
            </v-col>

            <v-col cols="12" sm="4">
              <v-autocomplete :items="subCategoriesItems" multiple v-model="itemEdited.sub_category_web"
                label="תת קטגוריה" filled rounded dense prepend-inner-icon="mdi-tag-multiple" chips
                small-chips></v-autocomplete>
            </v-col>

            <v-col cols="12" sm="4">
              <v-select :items="typesItems" multiple v-model="itemEdited.type_web" label="סוג" filled rounded dense
                prepend-inner-icon="mdi-format-list-bulleted-type" chips small-chips></v-select>
            </v-col>

            <v-col cols="12">
              <v-textarea v-model="itemEdited.details" label="מידע על המסעדה" filled rounded dense auto-grow rows="2"
                prepend-inner-icon="mdi-information-outline"></v-textarea>
            </v-col>

            <!-- Kashrut Section -->
            <v-col cols="12">
              <div class="section-title primary-color">כשרות</div>
            </v-col>

            <v-col cols="12" sm="4">
              <v-autocomplete :items="kashrutItems" v-model="itemEdited.kashrut.kashrut_name_web" label="כשרות" filled
                rounded dense prepend-inner-icon="mdi-check-circle"></v-autocomplete>
            </v-col>

            <v-col cols="12" sm="4" v-if="itemEdited.kashrut.kashrut_name_web === 'badatz_local'">
              <v-text-field v-model="itemEdited.kashrut.local_kashrut_name" label="כשרות מקומית" filled rounded dense
                prepend-inner-icon="mdi-check-decagram"></v-text-field>
            </v-col>

            <v-col cols="12" sm="4">
              <v-text-field v-model="itemEdited.kashrut.kashrut_details" label="פרטים נוספים על הכשרות" filled rounded
                dense prepend-inner-icon="mdi-information"></v-text-field>
            </v-col>

            <!-- Opening Hours Section -->
            <v-col cols="12">
              <div class="section-title primary-color">שעות פתיחה</div>
            </v-col>

            <v-col cols="12">
              <v-row>
                <v-col cols="12" sm="6" md="3" lg="3" xl="3" class="pa-2">
                  <v-card class="pa-3 rounded-card" outlined>
                    <div class="text-subtitle-1 font-weight-medium mb-2">יום א'</div>
                    <v-row dense>
                      <v-col cols="6">
                        <v-text-field v-model="itemEdited.open_hours.sunday.open" type="time" label="פתיחה" filled
                          rounded dense></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field v-model="itemEdited.open_hours.sunday.close" type="time" label="סגירה" filled
                          rounded dense></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>

                <v-col cols="12" sm="6" md="3" lg="3" xl="3" class="pa-2">
                  <v-card class="pa-3 rounded-card" outlined>
                    <div class="text-subtitle-1 font-weight-medium mb-2">יום ב'</div>
                    <v-row dense>
                      <v-col cols="6">
                        <v-text-field v-model="itemEdited.open_hours.monday.open" type="time" label="פתיחה" filled
                          rounded dense></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field v-model="itemEdited.open_hours.monday.close" type="time" label="סגירה" filled
                          rounded dense></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>

                <v-col cols="12" sm="6" md="3" lg="3" xl="3" class="pa-2">
                  <v-card class="pa-3 rounded-card" outlined>
                    <div class="text-subtitle-1 font-weight-medium mb-2">יום ג'</div>
                    <v-row dense>
                      <v-col cols="6">
                        <v-text-field v-model="itemEdited.open_hours.tuesday.open" type="time" label="פתיחה" filled
                          rounded dense></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field v-model="itemEdited.open_hours.tuesday.close" type="time" label="סגירה" filled
                          rounded dense></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>

                <v-col cols="12" sm="6" md="3" lg="3" xl="3" class="pa-2">
                  <v-card class="pa-3 rounded-card" outlined>
                    <div class="text-subtitle-1 font-weight-medium mb-2">יום ד'</div>
                    <v-row dense>
                      <v-col cols="6">
                        <v-text-field v-model="itemEdited.open_hours.wednesday.open" type="time" label="פתיחה" filled
                          rounded dense></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field v-model="itemEdited.open_hours.wednesday.close" type="time" label="סגירה" filled
                          rounded dense></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>

                <v-col cols="12" sm="6" md="3" lg="3" xl="3" class="pa-2">
                  <v-card class="pa-3 rounded-card" outlined>
                    <div class="text-subtitle-1 font-weight-medium mb-2">יום ה'</div>
                    <v-row dense>
                      <v-col cols="6">
                        <v-text-field v-model="itemEdited.open_hours.thursday.open" type="time" label="פתיחה" filled
                          rounded dense></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field v-model="itemEdited.open_hours.thursday.close" type="time" label="סגירה" filled
                          rounded dense></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>

                <v-col cols="12" sm="6" md="3" lg="3" xl="3" class="pa-2">
                  <v-card class="pa-3 rounded-card" outlined>
                    <div class="text-subtitle-1 font-weight-medium mb-2">יום ו'</div>
                    <v-row dense>
                      <v-col cols="6">
                        <v-text-field v-model="itemEdited.open_hours.friday.open" type="time" label="פתיחה" filled
                          rounded dense></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field v-model="itemEdited.open_hours.friday.close" type="time" label="סגירה" filled
                          rounded dense></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>

                <v-col cols="12" sm="6" md="3" lg="3" xl="3" class="pa-2">
                  <v-card class="pa-3 rounded-card" outlined>
                    <div class="text-subtitle-1 font-weight-medium mb-2">מוצ"ש</div>
                    <v-row dense>
                      <v-col cols="6">
                        <v-text-field v-model="itemEdited.open_hours.saturday.open" type="time" label="פתיחה" filled
                          rounded dense></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field v-model="itemEdited.open_hours.saturday.close" type="time" label="סגירה" filled
                          rounded dense></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12">
              <v-row>
                <v-col cols="auto">
                  <v-checkbox v-model="itemEdited.open_hours.only_weekend" :label="weekendTitle" hide-details
                    class="mt-0" color="#062a4e"></v-checkbox>
                </v-col>
                <v-col cols="auto">
                  <v-checkbox v-model="itemEdited.open_hours.isTemporarilyClosed" :label="tempClosedTitle" hide-details
                    class="mt-0" color="#062a4e"></v-checkbox>
                </v-col>
              </v-row>
            </v-col>

            <!-- Location Section -->
            <v-col cols="12">
              <div class="section-title primary-color">מיקום</div>
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field v-model="itemEdited.location.latitude" label="קו רוחב (Latitude)" filled rounded dense
                prepend-inner-icon="mdi-latitude" readonly></v-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field v-model="itemEdited.location.longitude" label="קו אורך (Longitude)" filled rounded dense
                prepend-inner-icon="mdi-longitude" readonly></v-text-field>
            </v-col>

            <v-col cols="12" class="d-flex flex-wrap align-center">
              <v-btn @click="chooseDeviceLocation()" color="#062a4e" dark class="mr-3 mb-3" rounded>
                <v-icon left>mdi-crosshairs-gps</v-icon>
                בחר את מיקום המכשיר
              </v-btn>

              <div class="text-body-2 grey--text text--darken-1 mb-3">
                <v-icon small color="grey darken-1">mdi-information-outline</v-icon>
                או בחר מיקום מהמפה
              </div>
            </v-col>

            <v-col cols="12">
              <!-- Search Field -->
              <div class="map-search-input">
                <GmapPlaceInput @place_changed="setPlace" ref="gmapInput" class="elevation-1 rounded-lg" />
              </div>

              <!-- Google Maps -->
              <div class="map-container">
                <GmapMap ref="gmapRef" :center="center" :zoom="12" style="width: 100%; height: 400px"
                  @click="mapClicked">
                  <GmapMarker :position="marker.position" @click="markerClicked"></GmapMarker>
                </GmapMap>
              </div>
            </v-col>

            <!-- Images Section -->
            <v-col cols="12">
              <div class="section-title primary-color">העלאת תמונות</div>
            </v-col>

            <v-col cols="12" sm="6" md="3">
              <div v-if="!itemEdited.logo" class="image-upload-container">
                <v-file-input label="לוגו" filled v-model="itemEdited.logo" accept="image/*" prepend-icon=""
                  prepend-inner-icon="mdi-camera" hide-details class="mb-0"></v-file-input>
              </div>
              <div v-else class="image-preview-container">
                <v-icon class="delete-icon" @click="itemEdited.logo = null">
                  mdi-delete
                </v-icon>
                <div class="text-caption primary-color pa-1">לוגו</div>
                <div class="image-wrapper">
                  <img id="logoPreview" class="image-preview"
                    :src="isString(itemEdited.logo) ? host + '/logo' + itemEdited.logo : showFileInput(itemEdited.logo, 'logoPreview')"
                    alt="Preview" v-if="itemEdited.logo" />
                </div>
              </div>
            </v-col>

            <v-col cols="12" sm="6" md="3">
              <div v-if="!itemEdited.kashrut.certificate" class="image-upload-container">
                <v-file-input label="תעודת כשרות" filled v-model="itemEdited.kashrut.certificate" accept="image/*"
                  prepend-icon="" prepend-inner-icon="mdi-camera" hide-details class="mb-0"></v-file-input>
              </div>
              <div v-else class="image-preview-container">
                <v-icon class="delete-icon" @click="itemEdited.kashrut.certificate = null">
                  mdi-delete
                </v-icon>
                <div class="text-caption primary-color pa-1">תעודת כשרות</div>
                <div class="image-wrapper">
                  <img id="certificatePreview" class="image-preview"
                    :src="isString(itemEdited.kashrut.certificate) ? host + '/certificates' + itemEdited.kashrut.certificate : showFileInput(itemEdited.kashrut.certificate, 'certificatePreview')"
                    alt="Preview" v-if="itemEdited.kashrut.certificate" />
                </div>
              </div>
            </v-col>

            <v-col v-for="(picture, index) in arrayPictures" :key="index" cols="12" sm="6" md="3">
              <div v-if="!itemEdited.pictures[index]" class="image-upload-container">
                <v-file-input :label="'תמונה ' + (index + 1)" filled v-model="itemEdited.pictures[index]"
                  accept="image/*" prepend-icon="" prepend-inner-icon="mdi-camera" hide-details
                  class="mb-0"></v-file-input>
              </div>
              <div v-else class="image-preview-container">
                <v-icon class="delete-icon" @click="handleDelete(index)">
                  mdi-delete
                </v-icon>
                <div class="text-caption primary-color pa-1">תמונה {{ (index + 1) }}</div>
                <div class="image-wrapper">
                  <img :id="'picturePreview' + index" class="image-preview"
                    :src="isString(itemEdited.pictures[index]) ? host + '/pictures' + itemEdited.pictures[index] : showFileInput(itemEdited.pictures[index], 'picturePreview' + index)"
                    alt="Preview" v-if="itemEdited.pictures[index]" />
                </div>
              </div>
            </v-col>

            <v-col cols="12" class="text-center mt-4">
              <div class="text-body-2 font-weight-medium mb-4">המידע יישלח לאישור טרם השמירה</div>
              <v-btn color="#062a4e" dark @click="sendRestaurant()" rounded x-large min-width="180"
                :loading="progressShow">
                <v-icon left>mdi-content-save</v-icon>
                שמור שינויים
              </v-btn>
            </v-col>
          </v-row>

          <!-- <v-row v-if="progressShow" justify="center" align="center" class="my-8">
            <v-progress-circular indeterminate color="#3F51B5" size="64"></v-progress-circular>
          </v-row> -->
        </v-container>
      </v-card-text>
    </v-card>

    <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>
  </div>
</template>

<script>
import SnackBar from '@/components/widgets/snackBar'
import Constants from '@/util/GetConstant'
import axios from 'axios'
import ApiServices from '@/services/api.service'
import Auth from '@/services/auth.service.js'

export default {
  components: {
    SnackBar
  },
  data() {
    return {
      progressShow: false,
      snackbar: false,
      snackbarColorBt: "green",
      snacbarText: "",
      host: "",
      itemEdited: {},
      objKashruyot: {},
      objTypes: {},
      objCategories: {},
      objSubCategories: {},
      objZones: {},
      objCountries: {},
      objCities: {},
      arrayPictures: ["", "", "", ""],
      weekendTitle: "פתוח רק בסופ\"ש",
      tempClosedTitle: "סגור זמנית",
      center: { lat: 32.0879976, lng: 34.8384455 },
      marker: {
        position: { lat: 32.0879976, lng: 34.8384455 }
      }
    }
  },
  methods: {
    async sendRestaurant() {
      if (!this.itemEdited.location.latitude) {
        return this.showSnackBar("לא נבחר מיקום", "red");
      }
      try {
        this.progressShow = true;
        this.itemEdited.city_web = this.itemEdited.city_web.value;
        this.itemEdited.country_web = this.itemEdited.country_web ? this.itemEdited.country_web.value : "";
        let token = localStorage.getItem("token");
        let api = process.env.VUE_APP_BASE_URL + `/user/edit_restaurant`;
        const formData = new FormData();
        formData.append('logo', this.itemEdited.logo ? this.itemEdited.logo : "");
        formData.append('cert', this.itemEdited.kashrut.certificate ? this.itemEdited.kashrut.certificate : "");
        formData.append('picture1', this.itemEdited.pictures[0] ? this.itemEdited.pictures[0] : "");
        formData.append('picture2', this.itemEdited.pictures[1] ? this.itemEdited.pictures[1] : "");
        formData.append('picture3', this.itemEdited.pictures[2] ? this.itemEdited.pictures[2] : "");
        formData.append('picture4', this.itemEdited.pictures[3] ? this.itemEdited.pictures[3] : "");
        // Destructure the fields you want to omit and use the rest operator to collect the remaining fields into a new object.
        const { logo, pictures, kashrut, ...otherDetails } = this.itemEdited;
        // If you also want to omit `kashrut.certificate`, you can destructure it further.
        const { certificate, ...remainingKashrut } = kashrut;
        // Now, `otherDetails` contains all fields from `itemEdited` except `logo`, `pictures`, and `kashrut.certificate`.
        // Add `kashrut` back into `otherDetails` without the `certificate`.
        otherDetails.kashrut = remainingKashrut;
        // Convert otherDetails object to JSON string
        const otherDetailsJSON = JSON.stringify(otherDetails);
        formData.append('otherDetails', otherDetailsJSON);
        const response = await axios.post(api, formData, {
          headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'multipart/form-data'
          }
        });
        this.progressShow = false;
        if (response.status === 200) {
          this.showSnackBar("הפרטים נשלחו בהצלחה!", "green");
        } else {
          // Handle other status codes
          this.showSnackBar("Received unexpected status code: " + response.status, "yellow");
        }
      } catch (error) {
        this.progressShow = false;
        this.showSnackBar("Error save new restaurant: " + error, "red");
      }
    },
    async getRestDetailsById() {
      try {
        this.progressShow = true;
        const nameSearchJSON = JSON.stringify({ idSelected: this.$route.params._id });
        const token = localStorage.getItem("token");
        let api = process.env.VUE_APP_BASE_URL + `/user/get_rest_for_edit`;

        const res = await fetch(api, ApiServices.requestOptions("POST", nameSearchJSON, token));
        const jsonObject = await res.json();
        console.log(jsonObject);

        this.progressShow = false;
        if (res.status >= 400) {
          this.showSnackBar("שגיאה בקבלת נתונים: " + jsonObject.message, "red");
        } else if (res.status === 200) {
          this.itemEdited = jsonObject;
          // Find matching city object
          const selectedCity = this.citiesItems.find(city => city.value === jsonObject.city_web);
          if (selectedCity) {
            this.itemEdited.city_web = selectedCity;
          }
          const selectedCountry = this.countriesItems.find(country => country.value === jsonObject.country_web);
          if (selectedCountry) {
            this.itemEdited.country_web = selectedCountry;
          }
          // Update map center and marker position if location data exists
          if (this.itemEdited.location && this.itemEdited.location.latitude && this.itemEdited.location.longitude) {
            this.center = {
              lat: parseFloat(this.itemEdited.location.latitude),
              lng: parseFloat(this.itemEdited.location.longitude)
            };
            this.marker.position = this.center;
          }
        }
      } catch (error) {
        this.progressShow = false;
        this.showSnackBar("Error get restaurants list: " + error, "red");
      }
    },
    chooseDeviceLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          position => {
            this.showPosition(position);
            { true }
          },
          error => {
            this.showSnackBar("שגיאה בקבלת המיקום: יש להפעיל את המיקום בלחיצה על האייקון למעלה בשורת הכתובת", "red");
          }
        );
      } else {
        this.showSnackBar("Geolocation is not supported", "red");
      }
    },
    showPosition(position) {
      this.itemEdited.location.latitude = position.coords.latitude;
      this.itemEdited.location.longitude = position.coords.longitude;

      // Update marker position
      this.marker.position = {
        lat: position.coords.latitude,
        lng: position.coords.longitude
      };

      // Update map center
      this.center = this.marker.position;
    },
    showSnackBar(message, color) {
      this.snacbarText = message;
      this.snackbarColorBt = color;
      this.snackbar = true
    },
    async getConstants() {
      const constants = await Constants.getConstant();
      if (!constants) {
        this.showSnackBar("שגיאה: יותר מדי כניסות מהכתובת הזאת, נסה שוב מאוחר יותר", "red");
      }
      this.objKashruyot = constants.kashruyot
      this.objTypes = constants.types;
      this.objCategories = constants.categories;
      this.objSubCategories = constants.sub_categories;
      this.objZones = constants.zones
      this.objCountries = constants.countries
      this.objCities = constants.cities
    },
    initializeEmptyObj() {
      this.itemEdited = {
        name: "",
        address: "",
        city: "",
        city_web: "",
        country: "",
        country_web: "",
        zone: "",
        zone_web: "",
        type: [],
        type_web: [],
        phone: "",
        website: "",
        category: "",
        category_web: "",
        sub_category: [],
        sub_category_web: [],
        details: "",
        pictures: [],
        logo: null,
        kashrut: { kashrut_name: "", kashrut_name_web: "", local_kashrut_name: "", kashrut_details: "", certificate: null },
        open_hours: {
          sunday: { open: "", close: "" }, monday: { open: "", close: "" }, tuesday: { open: "", close: "" },
          wednesday: { open: "", close: "" }, thursday: { open: "", close: "" }, friday: { open: "", close: "" }, saturday: { open: "", close: "" },
          only_weekend: false,
          isTemporarilyClosed: false
        },
        location: { latitude: "", longitude: "" },
        userId: Auth.getUserId(),
      }
    },
    setPlace(place) {
      this.marker.position = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng()
      };
      this.center = this.marker.position;

      // Update location in itemEdited
      this.itemEdited.location.latitude = place.geometry.location.lat();
      this.itemEdited.location.longitude = place.geometry.location.lng();
    },
    markerClicked(event) {
      // Instead of alert, show a snackbar with the coordinates
      this.showSnackBar(`מיקום נבחר: ${event.latLng.lat().toFixed(6)}, ${event.latLng.lng().toFixed(6)}`, "blue");
    },
    mapClicked(event) {
      this.marker.position = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng()
      };

      this.itemEdited.location.latitude = event.latLng.lat();
      this.itemEdited.location.longitude = event.latLng.lng();
    },
    handleDelete(index) {
      this.$set(this.itemEdited.pictures, index, null)
    },
    showFileInput(newFile, id) {
      if (!newFile) return '';

      setTimeout(() => {
        var image = document.getElementById(`${id}`);
        if (image) {
          // Set the src attribute to the object URL created from the file
          image.src = URL.createObjectURL(newFile);
          // Remember to revoke the object URL later to free up memory
          image.onload = () => URL.revokeObjectURL(image.src);
        }
      }, 300);
      return ''; // Return empty string as placeholder
    },
    isString(variable) {
      return typeof variable === 'string';
    },
    filterNumeric(value) {
      // Replace non-numeric characters with an empty string
      this.itemEdited.phone = value.replace(/\D+/g, '');
    }
  },
  computed: {
    kashrutItems() {
      return Object.entries(this.objKashruyot).map(([key, kashrut]) => ({
        value: key, // You can use the key as the value
        text: kashrut[this.$i18n.locale] || kashrut.en // or value.en for English
      }));
    },
    zonesItems() {
      return Object.entries(this.objZones).map(([key, zone]) => ({
        value: key, // You can use the key as the value
        text: zone[this.$i18n.locale] || zone.en // or value.en for English
      }));
    },
    typesItems() {
      return Object.entries(this.objTypes).map(([key, type]) => ({
        value: key, // You can use the key as the value
        text: type[this.$i18n.locale] || type.en // or value.en for English
      }));
    },
    categoriesItems() {
      return Object.entries(this.objCategories).map(([key, category]) => ({
        value: key, // You can use the key as the value
        text: category[this.$i18n.locale] || category.en // or value.en for English
      }));
    },
    subCategoriesItems() {
      return Object.entries(this.objSubCategories).map(([key, subCategory]) => ({
        value: key, // You can use the key as the value
        text: subCategory[this.$i18n.locale] || subCategory.en // or value.en for English
      }));
    },
    countriesItems() {
      return Object.entries(this.objCountries).map(([key, country]) => ({
        value: key, // You can use the key as the value
        text: country[this.$i18n.locale] || country.en // or value.en for English
      }));
    },
    citiesItems() {
      return Object.entries(this.objCities).map(([key, city]) => ({
        value: key, // Use the city key as the value
        text: city[this.$i18n.locale] || city.en // Use localized city name
      }));
    }
  },
  created() {
    this.initializeEmptyObj();
    this.getConstants();
    this.host = process.env.VUE_APP_BASE_URL;
  },
  mounted() {
    // change maps input style
    this.$nextTick(() => {
      const input = this.$refs.gmapInput.$el.querySelector('input');
      if (input) {
        input.style.backgroundColor = '#f5f7fa';
        input.style.padding = '12px 16px';
        input.style.border = '1px solid #dae1eb';
        input.style.borderRadius = '8px';
        input.style.width = '100%';
        input.style.maxWidth = '400px';
        input.style.fontSize = '14px';
      }
    });

    if (this.$route.params._id) {
      this.getRestDetailsById();
    }
  }
};
</script>

<style scoped>
.edit-restaurant-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 16px 64px;
}

/* Override Vuetify styles for better RTL support */
:deep(.v-text-field__slot) {
  direction: rtl;
}

:deep(.v-label) {
  right: 12px;
  left: auto;
}

:deep(.v-input__prepend-inner) {
  margin-right: 0;
  margin-left: 8px;
}

:deep(.v-text-field--filled .v-label) {
  right: 12px;
  left: auto;
}

:deep(.v-text-field--filled .v-input__prepend-inner) {
  margin-right: 12px;
  margin-left: 0;
}

:deep(.v-select__selections) {
  padding-right: 12px;
}

:deep(.v-chip) {
  margin: 2px;
}

:deep(.v-messages) {
  text-align: right;
}

.image-upload-container {
  border: 2px dashed #dae1eb;
  border-radius: 8px;
  background-color: #f5f7fa;
  padding: 16px;
  text-align: center;
  transition: all 0.3s ease;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.image-upload-container:hover {
  border-color: #3f51b5;
  background-color: #f0f3f8;
}

.image-preview-container {
  position: relative;
  width: 100%;
  height: 150px;
  background-color: #f5f7fa;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid #dae1eb;
}

.image-wrapper {
  width: 100%;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.image-preview {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.delete-icon {
  position: absolute;
  top: 8px;
  right: 8px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  padding: 4px;
  cursor: pointer;
  z-index: 10;
  color: #f44336;
}

.map-container {
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  margin-top: 16px;
}

.map-search-input {
  margin-bottom: 16px;
  width: 100%;
  max-width: 400px;
}

.section-title {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #dae1eb;
}
</style>