<template>
    <v-container class="settings-container">
      <v-card class="rounded-card card-shadow" elevation="0">
        <v-card-title class="d-flex justify-center pt-6 pb-2">
          <h1 class="text-h4 primary-color font-weight-bold">{{ $t('user.settings_title') }} </h1>
        </v-card-title>
  
        <v-card-text class="form-container">
          <v-alert
            v-if="message2"
            :color="message2 === $t('user.alert_success') ? 'success' : 'error'"
            dense
            text
            class="mb-4"
          >
            {{ message2 }}
          </v-alert>
  
          <v-row>
            <v-col cols="12" md="8" lg="6" class="mx-auto">
              <v-card outlined class="rounded-card pa-4">
                <div class="section-title primary-color">{{ $t('user.settings_title_2') }}</div>
                
                <p class="text-body-2 grey--text text--darken-1 mb-4">
                  {{ $t('user.settings_title_3') }}
                </p>
                
                <v-select
                  v-model="selectedKashruyot"
                  :items="formattedKashruyot"
                  :label="$t('user.settings_choose_kashruyot')"
                  multiple
                  chips
                  small-chips
                  filled
                  rounded
                  dense
                  :item-text="lang"
                  item-value="key"
                  prepend-inner-icon="mdi-check-circle"
                ></v-select>
                
                <div class="d-flex justify-end mt-4">
                  <v-btn
                    color="grey"
                    outlined
                    class="ml-3"
                    @click="createOrRemoveFavoritesKashruyot('remove')"
                  >
                    <v-icon left small>mdi-refresh</v-icon>
              {{ $t('user.settings_reset') }}
                  </v-btn>
                  
                  <v-btn
                    color="#062a4e"
                    dark
                    @click="createOrRemoveFavoritesKashruyot('create')"
                  >
                    <v-icon left small>mdi-content-save</v-icon>
                    {{ $t('user.settings_save') }}
                  </v-btn>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>
  </template>
  
  <script>
  import ApiServices from '@/services/api.service'
  import Constants from '@/util/GetConstant'
  
  export default {
    data() {
      return {
        message2: "",
        activeTab: 0,
        adminSettings: true,
        selectedKashruyot: [],
        kashruyotOptions: {},
        lang: this.$i18n.locale,
      }
    },
    computed: {
      // Convert the kashruyotOptions object into an array for the v-select
      formattedKashruyot() {
        console.log(Object.keys(this.kashruyotOptions).map(key => ({
          key: key,  // The dynamic key name (e.g., 'badatz_mehadrin_rubin')
          ...this.kashruyotOptions[key] // Merge the content (he and en) into the object
        })));
        
        return Object.keys(this.kashruyotOptions).map(key => ({
          key: key,  // The dynamic key name (e.g., 'badatz_mehadrin_rubin')
          ...this.kashruyotOptions[key] // Merge the content (he and en) into the object
        }));
      }
    },
    methods: {
      hideMessage() {
        setTimeout(() => {
          this.message2 = "";
        }, 4000);
      },
      async getConstans() {
        if (this.$store.state.constant?.categories?.restaurant) {
          this.kashruyotOptions = this.$store.state.constant.kashruyot
        } else {
          const constants = await Constants.getConstant();
          if (!constants) {
            this.message2 = this.$t('user.alert_error');;
            this.hideMessage();
            return;
          }
          this.kashruyotOptions = constants.kashruyot;
          this.$store.state.constant = constants;
        }
      },
      async createOrRemoveFavoritesKashruyot(action) {
        try {
          let api = process.env.VUE_APP_BASE_URL + "/user/add_or_remove_favorites_kashruyot";
          const token = localStorage.getItem("token");
          const jsonBody = JSON.stringify({ action, list: this.selectedKashruyot });
          const res = await fetch(api, ApiServices.requestOptions("POST", jsonBody, token));
          if (res.status >= 400) {
            this.message2 = this.$t('user.alert_error');
            this.hideMessage();
          } else if (res.status === 200) {
            this.message2 = this.$t('user.alert_success');
            if (action === "remove") {
              this.selectedKashruyot = [];
            }
            this.hideMessage();
          }
        } catch (error) {
          this.message2 = error.message;
          this.hideMessage();
        }
      },
      async getUserFavoritesKashruyot() {
        try {
          let api = process.env.VUE_APP_BASE_URL + "/user/get_user_favorites_kashruyot";
          const token = localStorage.getItem("token");
          const res = await fetch(api, ApiServices.requestOptions("GET", "", token));
          const jsonObject = await res.json();
          if (res.status >= 400) {
            this.message2 = this.$t('user.alert_error');;
            this.hideMessage();
          } else if (res.status === 200) {
            this.selectedKashruyot = jsonObject.favorites_kashruyot;
          }
        } catch (error) {
          this.message2 = error.message;
          this.hideMessage();
        }
      }
    },
    mounted() {
      this.getConstans();
      this.getUserFavoritesKashruyot();
    },
  }
  </script>
  
  <style scoped>
  .settings-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 16px 64px;
  }
  
  /* Override Vuetify styles for better RTL support */
  :deep(.v-text-field__slot) {
    direction: rtl;
  }
  
  :deep(.v-label) {
    right: 12px;
    left: auto;
  }
  
  :deep(.v-input__prepend-inner) {
    margin-right: 0;
    margin-left: 8px;
  }
  
  :deep(.v-text-field--filled .v-label) {
    right: 12px;
    left: auto;
  }
  
  :deep(.v-text-field--filled .v-input__prepend-inner) {
    margin-right: 12px;
    margin-left: 0;
  }
  
  :deep(.v-select__selections) {
    padding-right: 12px;
  }
  
  :deep(.v-chip) {
    margin: 2px;
  }
  
  :deep(.v-messages) {
    text-align: right;
  }
  </style>