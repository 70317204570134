<template>
  <v-container class="auth-container">
    <v-row justify="center" align="center" class="fill-height">
      <v-col cols="12" sm="8" md="6" lg="4">
        <v-card class="auth-card elevation-12" :class="{ 'on-hover': hover }" @mouseover="hover = true" @mouseleave="hover = false">
          <div class="text-center pt-6">
            <v-img :src="require('../../assets/default_logo.png')" class="auth-logo mx-auto" contain height="120" 
                  :class="{ 'shrink': isResponsive }"/>
          </div>
          
          <v-card-title class="justify-center headline primary--text pb-2">
            {{ $t('auth.login_title') }}
          </v-card-title>

          <v-card-text>
            <v-form ref="form" @submit.prevent="login" v-model="valid">
              <v-text-field
                v-model="email"
                :label="$t('auth.email')"
                prepend-icon="mdi-email"
                type="email"
                :rules="emailRules"
                required
                outlined
                dense
                color="primary"
                class="mb-2"
              ></v-text-field>

              <v-text-field
                v-model="password"
                :label="$t('auth.password')"
                prepend-icon="mdi-lock"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                @click:append="showPassword = !showPassword"
                :rules="[v => !!v || 'שדה חובה']"
                required
                outlined
                dense
                color="primary"
              ></v-text-field>

              <div class="d-flex justify-space-between align-center mt-2 mb-4">
                <v-btn text small color="primary" @click="forgotPassword" :disabled="!email">
                  שכחתי סיסמה
                </v-btn>
                <v-btn text small color="primary" @click="register">
                  הירשם עכשיו
                </v-btn>
              </div>

              <v-btn
                block
                color="primary"
                large
                @click="login"
                :loading="progressShow"
                :disabled="!valid || progressShow"
                class="mb-3"
              >
                כניסה
                <v-icon right>mdi-login</v-icon>
              </v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-snackbar
      v-model="snackbar"
      :color="snackbarColorBt"
      :timeout="3000"
      top
    >
      {{ snacbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          סגור
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import ApiServices from '@/services/api.service'
import Auth from '@/services/auth.service.js'

export default {
  name: 'FormLogin',
  
  data: () => ({
    valid: false,
    hover: false,
    isResponsive: false,
    email: "",
    password: "",
    showPassword: false,
    progressShow: false,
    snackbar: false,
    snackbarColorBt: "success",
    snacbarText: "",
    recaptchaToken: "",
    emailRules: [
      v => !!v || 'שדה חובה',
      v => /.+@.+\..+/.test(v) || 'יש להזין כתובת אימייל תקינה'
    ]
  }),

  methods: {
    async login() {
      if (this.$refs.form.validate()) {
        this.progressShow = true;
        try {
          await this.$recaptchaLoaded();
          this.recaptchaToken = await this.$recaptcha('login');
          
          if (!this.recaptchaToken) {
            this.showSnackBar("CAPTCHA validation failed", "error");
            return;
          }

          const response = await fetch(
            `${process.env.VUE_APP_BASE_URL}/auth/login`,
            ApiServices.requestOptions("POST", JSON.stringify({
              email: this.email,
              password: this.password,
              recaptchaToken: this.recaptchaToken
            }), "")
          );

          const data = await response.json();
          
          if (response.status === 200) {
            localStorage.setItem('token', data.token);
            this.$store.commit('setLoggedIn', true);
            const role = Auth.getUserRole();
            this.$router.replace({ name: role === "admin" ? "statistics" : "home" });
          } else {
            this.showSnackBar(
              response.status === 429 
                ? "יותר מדי נסיונות כניסה" 
                : `שגיאת התחברות: ${data.message}`,
              "error"
            );
          }
        } catch (error) {
          this.showSnackBar(`Error Login: ${error}`, "error");
        } finally {
          this.progressShow = false;
        }
      }
    },

    register() {
      this.$emit('register');
    },

    async forgotPassword() {
      if (!this.email) {
        this.showSnackBar("יש להזין אימייל לצורך איפוס הסיסמה", "warning");
        return;
      }

      this.progressShow = true;
      try {
        const response = await fetch(
          `${process.env.VUE_APP_BASE_URL}/auth/forgotPassword`,
          ApiServices.requestOptions("POST", JSON.stringify({ email: this.email }), "")
        );

        const status = response.status;
        if (status === 200) {
          this.showSnackBar("נשלח מייל לאיפוס הסיסמה", "success");
        } else {
          const messages = {
            401: "שגיאה: אימייל לא קיים",
            429: "יותר מדי נסיונות כניסה",
            400: "שגיאת שרת"
          };
          this.showSnackBar(messages[status] || "שגיאה לא ידועה", "error");
        }
      } catch (error) {
        this.showSnackBar(`Error: ${error}`, "error");
      } finally {
        this.progressShow = false;
      }
    },

    showSnackBar(message, color) {
      this.snacbarText = message;
      this.snackbarColorBt = color;
      this.snackbar = true;
    },

    handleResize() {
      this.isResponsive = window.innerWidth <= 500;
    }
  },

  mounted() {
    document.title = 'Login | Eat Kosher';
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  }
}
</script>

<style lang="scss" scoped>
.auth-container {
  min-height: 100vh;
  padding-top: 50px;
  background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
}

.auth-card {
  border-radius: 16px;
  transition: transform 0.3s ease-in-out;
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);

  &.on-hover {
    transform: translateY(-5px);
  }
}

.auth-logo {
  transition: all 0.3s ease;
  
  &.shrink {
    width: 60%;
  }
}

.v-btn {
  text-transform: none;
  letter-spacing: 0.5px;
}

.headline {
  font-weight: 600;
  letter-spacing: 0.5px;
}

.v-text-field {
  border-radius: 8px;
  
  ::v-deep .v-input__slot {
    transition: all 0.3s ease;
    
    &:hover {
      background: rgba(0, 0, 0, 0.03);
    }
  }
}
</style>