<template>
  <div class="statistics-container">
    <div class="header-banner primary-bg">
      <h1 class="header-title">סטטיסטיקות</h1>
    </div>

    <v-container fluid class="mt-6">
      <!-- Stats Cards -->
      <v-row class="stats-cards-row">
        <v-col cols="12" sm="6" md="4" lg="2" v-for="(stat, index) in statsCards" :key="index">
          <v-card class="stats-card rounded-card card-shadow" elevation="0">
            <v-card-text class="text-center">
              <div class="stats-icon-wrapper mb-2" :class="stat.iconClass">
                <v-icon size="32" :color="stat.iconColor">{{ stat.icon }}</v-icon>
              </div>
              <div class="stats-title">{{ stat.title }}</div>
              <div class="stats-value">{{ stat.value }}</div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <!-- Loading Indicator -->
      <v-row v-if="progressShow" class="my-6">
        <v-col class="d-flex justify-center">
          <v-progress-circular indeterminate color="#3F51B5" size="64"></v-progress-circular>
        </v-col>
      </v-row>

      <!-- Daily Chart -->
      <v-row class="chart-row">
        <v-col cols="12">
          <v-card class="chart-card rounded-card card-shadow" elevation="0">
            <v-card-title class="chart-title primary-color">
              <v-icon left color="#062a4e">mdi-chart-line</v-icon>
              נתונים יומיים - 15 ימים אחרונים
            </v-card-title>
            <v-card-text class="chart-container">
              <canvas id="myChart" ref="dailyChart"></canvas>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <!-- Monthly Chart -->
      <v-row class="chart-row">
        <v-col cols="12">
          <v-card class="chart-card rounded-card card-shadow" elevation="0">
            <v-card-title class="chart-title primary-color">
              <v-icon left color="#062a4e">mdi-chart-bar</v-icon>
              נתונים חודשיים - 12 חודשים אחרונים
            </v-card-title>
            <v-card-text class="chart-container">
              <canvas id="myChart2" ref="monthlyChart"></canvas>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>
  </div>
</template>

<script>
import SnackBar from '@/components/widgets/snackBar.vue'
import Chart from 'chart.js/auto';
import ApiServices from '@/services/api.service'

export default {
  components: {
    SnackBar,
  },
  data: () => ({
    webRequest: [],
    appRequest: [],
    webUsersEntrance: [],
    appUsersEntrance: [],
    allRequestFromToday: 0,
    allUsersEntranceFromToday: 0,
    progressShow: false,
    snackbar: false,
    snacbarText: "",
    snackbarColorBt: "green",
    chartInstance: null, // Keep track of the chart instance
    chartInstance2: null, // Keep track of the chart instance
  }),
  computed: {
    statsCards() {
      return [
        {
          title: "משתמשים אפליקציה",
          value: this.appUsersEntrance.length || 0,
          icon: "mdi-cellphone",
          iconColor: "#4CAF50",
          iconClass: "bg-light-green"
        },
        {
          title: "משתמשים אתר",
          value: this.webUsersEntrance.length || 0,
          icon: "mdi-desktop-mac",
          iconColor: "#2196F3",
          iconClass: "bg-light-blue"
        },
        {
          title: "פניות אפליקציה",
          value: this.appRequest.length || 0,
          icon: "mdi-message-text",
          iconColor: "#FF9800",
          iconClass: "bg-light-orange"
        },
        {
          title: "פניות אתר",
          value: this.webRequest.length || 0,
          icon: "mdi-email",
          iconColor: "#9C27B0",
          iconClass: "bg-light-purple"
        },
        {
          title: "משתמשים היום",
          value: this.allUsersEntranceFromToday || 0,
          icon: "mdi-account-group",
          iconColor: "#F44336",
          iconClass: "bg-light-red"
        },
        {
          title: "פניות היום",
          value: this.allRequestFromToday || 0,
          icon: "mdi-message-processing",
          iconColor: "#3F51B5",
          iconClass: "bg-light-indigo"
        }
      ];
    }
  },
  methods: {
    async getDetails() {
      try {
        this.progressShow = true;
        let token = localStorage.getItem("token");
        let api = process.env.VUE_APP_BASE_URL + `/admin/get_statistics`;
        const res = await fetch(api, ApiServices.requestOptions("GET", "", token));
        const jsonObject = await res.json();
        this.progressShow = false;

        if (res.status >= 400) {
          this.showSnackBar("שגיאה בקבלת נתונים", "red");
        } else if (res.status === 200) {
          this.webRequest = jsonObject.statisticsRequestWeb || [];
          this.appRequest = jsonObject.statisticsRequestApp || [];
          this.webUsersEntrance = jsonObject.uniqueVisitorstWeb || [];
          this.appUsersEntrance = jsonObject.uniqueVisitorsApp || [];

          // Process daily data
          const webUsersEntranceCounts = jsonObject.webUniqueVisitorsLast15Days || {};
          const appUsersEntranceCounts = jsonObject.appUniqueVisitorsLast15Days || {};
          const webRequestCounts = this.countByDate(jsonObject.statisticsRequestWeb || []);
          const appRequestCounts = this.countByDate(jsonObject.statisticsRequestApp || []);

          this.allRequestFromToday = this.todayCount(webRequestCounts) + this.todayCount(appRequestCounts);
          this.allUsersEntranceFromToday = this.todayCount(appUsersEntranceCounts) + this.todayCount(webUsersEntranceCounts);

          // Initialize daily chart with a slight delay to ensure DOM is ready
          this.$nextTick(() => {
            this.initializeChart("myChart", webRequestCounts, appRequestCounts, webUsersEntranceCounts, appUsersEntranceCounts, "יומי");
          });

          // Process monthly data
          const webUsersEntranceMonthsCounts = jsonObject.webUniqueVisitorsLast12Months || {};
          const appUsersEntranceMonthsCounts = jsonObject.appUniqueVisitorsLast12Months || {};
          const webRequestMonthsCounts = this.countByMonth(jsonObject.statisticsRequestWeb || []);
          const appRequestMonthsCounts = this.countByMonth(jsonObject.statisticsRequestApp || []);

          // Initialize monthly chart with a slight delay
          this.$nextTick(() => {
            this.initializeChart("myChart2", webRequestMonthsCounts, appRequestMonthsCounts, webUsersEntranceMonthsCounts, appUsersEntranceMonthsCounts, "חודשי");
          });
        }
      } catch (error) {
        this.progressShow = false;
        this.showSnackBar("שגיאה בטעינת הנתונים: " + error.message, "red");
      }
    },

    // Helper function to count entries by date
    countByDate(entries) {
      const counts = {};
      const today = new Date();
      const startDate = new Date();
      startDate.setDate(today.getDate() - 14); // 15 days including today

      // Initialize counts for each day to 0
      for (let i = 0; i < 15; i++) {
        const date = new Date(startDate);
        date.setDate(startDate.getDate() + i);
        const formattedDate = this.formatDate(date);
        counts[formattedDate] = 0;
      }

      // Filter and count entries
      entries.forEach(entry => {
        const entryDate = new Date(entry.createdAt);
        const formattedEntryDate = this.formatDate(entryDate);
        if (formattedEntryDate in counts) {
          counts[formattedEntryDate]++;
        }
      });

      return counts;
    },

    // Format date as 'DD-MM-YYYY'
    formatDate(d) {
      let month = '' + (d.getMonth() + 1);
      let day = '' + d.getDate();
      let year = d.getFullYear();

      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;

      return [day, month, year].join('-');
    },

    // Helper function to count entries by month
    countByMonth(entries) {
      const counts = {};
      const today = new Date();
      const startDate = new Date(today.getFullYear(), today.getMonth() - 11, 1); // 12 months including the current month

      // Initialize counts for each month to 0
      for (let i = 0; i < 12; i++) {
        const date = new Date(startDate);
        date.setMonth(startDate.getMonth() + i);
        const formattedDate = this.formatMonth(date);
        counts[formattedDate] = 0;
      }

      // Filter and count entries
      entries.forEach(entry => {
        const entryDate = new Date(entry.createdAt);
        const formattedEntryDate = this.formatMonth(entryDate);
        if (formattedEntryDate in counts) {
          counts[formattedEntryDate]++;
        }
      });

      return counts;
    },

    // Format date as 'MM-YYYY'
    formatMonth(d) {
      let month = '' + (d.getMonth() + 1);
      let year = d.getFullYear();

      if (month.length < 2) month = '0' + month;

      return [month, year].join('-');
    },

    // Get count for today
    todayCount(data) {
      const today = new Date();
      const dd = String(today.getDate()).padStart(2, '0');
      const mm = String(today.getMonth() + 1).padStart(2, '0');
      const yyyy = today.getFullYear();
      const formattedToday = dd + '-' + mm + '-' + yyyy;
      return data[formattedToday] || 0;
    },

    // Format labels for better display
    formatLabels(labels, type) {
      if (type === "יומי") {
        // For daily labels, show only day and month
        return labels.map(label => {
          const parts = label.split('-');
          return parts[0] + '/' + parts[1]; // DD/MM format
        });
      } else {
        // For monthly labels, convert to Hebrew month names
        const hebrewMonths = ['ינואר', 'פברואר', 'מרץ', 'אפריל', 'מאי', 'יוני', 'יולי', 'אוגוסט', 'ספטמבר', 'אוקטובר', 'נובמבר', 'דצמבר'];
        return labels.map(label => {
          const parts = label.split('-');
          const monthIndex = parseInt(parts[0], 10) - 1;
          return hebrewMonths[monthIndex] + ' ' + parts[1]; // Hebrew month name + year
        });
      }
    },

    initializeChart(chartId, webRequestCountsArg, appRequestCountsArg, webUsersEntranceCountsArg, appUsersEntranceCountsArg, type) {
      const labels = Object.keys(webRequestCountsArg);
      const formattedLabels = this.formatLabels(labels, type);

      const webRequestCounts = Object.values(webRequestCountsArg);
      const appRequestCounts = Object.values(appRequestCountsArg);
      const webUsersEntrance = Object.values(webUsersEntranceCountsArg);
      const appUsersEntrance = Object.values(appUsersEntranceCountsArg);

      const data = {
        labels: formattedLabels,
        datasets: [
          {
            label: 'משתמשים אפליקציה',
            data: appUsersEntrance,
            backgroundColor: 'rgba(76, 175, 80, 0.4)',
            borderColor: 'rgba(76, 175, 80, 1)',
            borderWidth: 2,
            borderRadius: 4,
            barThickness: 12,
          },
          {
            label: 'משתמשים אתר',
            data: webUsersEntrance,
            backgroundColor: 'rgba(33, 150, 243, 0.4)',
            borderColor: 'rgba(33, 150, 243, 1)',
            borderWidth: 2,
            borderRadius: 4,
            barThickness: 12,
          },
          {
            label: 'פניות אפליקציה',
            data: appRequestCounts,
            backgroundColor: 'rgba(255, 152, 0, 0.4)',
            borderColor: 'rgba(255, 152, 0, 1)',
            borderWidth: 2,
            borderRadius: 4,
            barThickness: 12,
          },
          {
            label: 'פניות אתר',
            data: webRequestCounts,
            backgroundColor: 'rgba(156, 39, 176, 0.4)',
            borderColor: 'rgba(156, 39, 176, 1)',
            borderWidth: 2,
            borderRadius: 4,
            barThickness: 12,
          }
        ]
      };

      // Destroy existing chart instance if it exists
      if (chartId === "myChart") {
        if (this.chartInstance) {
          this.chartInstance.destroy();
        }
      } else {
        if (this.chartInstance2) {
          this.chartInstance2.destroy();
        }
      }

      // Get the canvas context
      const ctx = document.getElementById(chartId);
      if (!ctx) return;

      // Chart configuration
      const chartConfig = {
        type: 'bar',
        data: data,
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              position: 'top',
              labels: {
                font: {
                  family: 'Rubik, Assistant, sans-serif',
                  size: 14
                },
                padding: 20
              }
            },
            tooltip: {
              backgroundColor: 'rgba(255, 255, 255, 0.9)',
              titleColor: '#062a4e',
              bodyColor: '#333',
              borderColor: '#dae1eb',
              borderWidth: 1,
              padding: 12,
              cornerRadius: 8,
              titleFont: {
                family: 'Rubik, Assistant, sans-serif',
                size: 14,
                weight: 'bold'
              },
              bodyFont: {
                family: 'Rubik, Assistant, sans-serif',
                size: 13
              },
              displayColors: true,
              boxWidth: 10,
              boxHeight: 10,
              boxPadding: 3,
              usePointStyle: true
            }
          },
          scales: {
            y: {
              beginAtZero: true,
              ticks: {
                stepSize: 1,
                precision: 0,
                font: {
                  family: 'Rubik, Assistant, sans-serif',
                  size: 12
                }
              },
              grid: {
                color: 'rgba(0, 0, 0, 0.05)',
                drawBorder: false
              }
            },
            x: {
              ticks: {
                font: {
                  family: 'Rubik, Assistant, sans-serif',
                  size: 12
                },
                maxRotation: 45,
                minRotation: 45
              },
              grid: {
                display: false
              }
            }
          },
          animation: {
            duration: 1000,
            easing: 'easeOutQuart'
          }
        }
      };

      // Create the chart
      const chartInstance = new Chart(ctx, chartConfig);

      // Store the chart instance
      if (chartId === "myChart") {
        this.chartInstance = chartInstance;
      } else {
        this.chartInstance2 = chartInstance;
      }
    },

    showSnackBar(message, color) {
      this.snacbarText = message;
      this.snackbarColorBt = color;
      this.snackbar = true;
    },
    handleResize() {
      // Debounce resize event to prevent performance issues
      if (this.resizeTimeout) clearTimeout(this.resizeTimeout);
      this.resizeTimeout = setTimeout(() => {
        if (this.chartInstance) {
          this.chartInstance.resize();
        }
        if (this.chartInstance2) {
          this.chartInstance2.resize();
        }
      }, 200);
    }
  },
  mounted() {
    this.getDetails();

    // Add window resize event listener for responsive charts
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    // Clean up chart instances and event listeners
    if (this.chartInstance) {
      this.chartInstance.destroy();
    }
    if (this.chartInstance2) {
      this.chartInstance2.destroy();
    }
    window.removeEventListener('resize', this.handleResize);
  }
}
</script>

<style scoped>
.statistics-container {
  background-color: #f5f7fa;
  min-height: 100vh;
}

.header-banner {
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.header-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: white;
  margin: 0;
}

.stats-cards-row {
  margin-bottom: 2rem;
}

.stats-card {
  height: 100%;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  overflow: hidden;
  padding: 0.5rem;
}

.stats-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1) !important;
}

.stats-icon-wrapper {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.bg-light-green {
  background-color: rgba(76, 175, 80, 0.1);
}

.bg-light-blue {
  background-color: rgba(33, 150, 243, 0.1);
}

.bg-light-orange {
  background-color: rgba(255, 152, 0, 0.1);
}

.bg-light-purple {
  background-color: rgba(156, 39, 176, 0.1);
}

.bg-light-red {
  background-color: rgba(244, 67, 54, 0.1);
}

.bg-light-indigo {
  background-color: rgba(63, 81, 181, 0.1);
}

.stats-title {
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 0.5rem;
}

.stats-value {
  font-size: 1.8rem;
  font-weight: 700;
  color: #062a4e;
}

.chart-row {
  margin-bottom: 2rem;
}

.chart-card {
  overflow: hidden;
}

.chart-title {
  font-size: 1.25rem;
  font-weight: 600;
  padding: 1rem 1.5rem;
  border-bottom: 1px solid #eee;
}

.chart-container {
  height: 500px;
  padding: 1.5rem;
  position: relative;
}

.rounded-card {
  border-radius: 12px !important;
}

.card-shadow {
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08) !important;
}

.primary-color {
  color: #062a4e;
}

.primary-bg {
  background-color: #062a4e;
}

/* RTL specific adjustments */
:deep(.v-application--is-rtl) .stats-title,
:deep(.v-application--is-rtl) .stats-value,
:deep(.v-application--is-rtl) .chart-title {
  direction: rtl;
  text-align: center;
}

@media (max-width: 960px) {
  .chart-container {
    height: 400px;
  }

  .stats-value {
    font-size: 1.5rem;
  }
}

@media (max-width: 600px) {
  .chart-container {
    height: 350px;
  }

  .stats-icon-wrapper {
    width: 50px;
    height: 50px;
  }

  .stats-value {
    font-size: 1.3rem;
  }
}
</style>