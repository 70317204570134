<template>
  <div class="auth-page">
    <transition name="fade" mode="out-in">
      <FormLogin v-if="loginComp" @register="openRegister" key="login" />
      <FormRegister v-else @login="openLogin" key="register" />
    </transition>
  </div>
</template>

<script>
import FormLogin from '@/components/auth/FormLogin.vue'
import FormRegister from '@/components/auth/FormRegister.vue'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import Vue from 'vue'

export default {
  name: 'Auth',
  
  components: {
    FormLogin,
    FormRegister
  },

  data: () => ({
    loginComp: true
  }),

  methods: {
    openRegister() {
      this.loginComp = false;
    },
    openLogin() {
      this.loginComp = true;
    }
  },

  mounted() {
    Vue.use(VueReCaptcha, {
      siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY
    });
  }
}
</script>

<style lang="scss" scoped>
.auth-page {
  width: 100%;
  min-height: 100vh;
  background-color: rgb(238, 242, 246);
  transform: translateZ(0);
  animation: colorIn 2.5s ease-out;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
  transform: translateY(20px);
}

@keyframes colorIn {
  from {
    filter: grayscale(1);
  }
  to {
    filter: grayscale(0);
  }
}
</style>