<template>
    <v-container class="settings-container">
      <v-card class="rounded-card card-shadow" elevation="0">
        <v-card-title class="d-flex justify-center pt-6 pb-2">
          <h1 class="text-h4 primary-color font-weight-bold">הגדרות מערכת</h1>
        </v-card-title>
  
        <v-card-text class="form-container">
          <v-row>
            <!-- Admin Settings Section -->
            <v-col cols="12" md="6">
              <v-card outlined class="rounded-card pa-4 mb-6">
                <div class="section-title primary-color">ניהול מערכת</div>
                
                <v-alert
                  v-if="message"
                  :color="message === 'בוצע בהצלחה' ? 'success' : 'error'"
                  dense
                  text
                  class="mb-4"
                >
                  {{ message }}
                </v-alert>
                
                <v-btn 
                  color="#062a4e" 
                  dark 
                  @click="cleanCache()" 
                  class="mb-6"
                  rounded
                >
                  <v-icon left>mdi-cached</v-icon>
                  ניקוי זיכרון זמני
                </v-btn>
                
                <v-form @submit.prevent="submitHandler" ref="form">
                  <div class="section-subtitle primary-color mb-3">הוספת עיר או מדינה</div>
                  
                  <v-radio-group 
                    v-model="selectedOption" 
                    row 
                    class="mb-3"
                  >
                    <v-radio 
                      label="הוספת עיר" 
                      value="city"
                      color="#062a4e"
                    ></v-radio>
                    <v-radio 
                      label="הוספת מדינה" 
                      value="country"
                      color="#062a4e"
                    ></v-radio>
                  </v-radio-group>
                  
                  <div class="mb-4">
                    <div class="text-body-2 font-weight-medium mb-2">הוסף שם בעברית</div>
                    <v-text-field 
                      v-model="newCityOrCountryHe" 
                      :label="selectedOption === 'city' ? 'שם העיר בעברית' : 'שם המדינה בעברית'"
                      filled 
                      rounded 
                      dense
                      prepend-inner-icon="mdi-translate"
                      :rules="[v => !!v || 'שדה חובה']" 
                      required
                    ></v-text-field>
                  </div>
                  
                  <div class="mb-4">
                    <div class="text-body-2 font-weight-medium mb-2">הוסף שם באנגלית</div>
                    <v-text-field 
                      v-model="newCityOrCountryEn" 
                      :label="selectedOption === 'city' ? 'שם העיר באנגלית' : 'שם המדינה באנגלית'"
                      filled 
                      rounded 
                      dense
                      prepend-inner-icon="mdi-translate"
                      :rules="[v => !!v || 'שדה חובה']" 
                      required
                    ></v-text-field>
                  </div>
                  
                  <div class="d-flex justify-end">
                    <v-btn 
                      color="#062a4e" 
                      dark 
                      @click="addCityOrCountry()"
                      rounded
                    >
                      <v-icon left>mdi-plus</v-icon>
                      הוסף
                    </v-btn>
                  </div>
                </v-form>
              </v-card>
            </v-col>
            
            <!-- User Settings Section -->
            <v-col cols="12" md="6">
              <v-card outlined class="rounded-card pa-4">
                <div class="section-title primary-color">הגדרת סינון קבוע לפי כשרויות</div>
                
                <v-alert
                  v-if="message2"
                  :color="message2 === 'בוצע בהצלחה' ? 'success' : 'error'"
                  dense
                  text
                  class="mb-4"
                >
                  {{ message2 }}
                </v-alert>
                
                <p class="text-body-2 grey--text text--darken-1 mb-4">
                  בחר את סוגי הכשרות המועדפים עליך לסינון קבוע של תוצאות החיפוש
                </p>
                
                <v-select
                  v-model="selectedKashruyot"
                  :items="formattedKashruyot"
                  label="בחר כשרויות"
                  multiple
                  chips
                  small-chips
                  filled
                  rounded
                  dense
                  :item-text="lang"
                  item-value="key"
                  prepend-inner-icon="mdi-check-circle"
                ></v-select>
                
                <div class="d-flex justify-end mt-4">
                  <v-btn
                    color="#f5f7fa"
                    outlined
                    class="mr-3"
                    @click="createOrRemoveFavoritesKashruyot('remove')"
                    rounded
                  >
                    <v-icon left small>mdi-refresh</v-icon>
                    איפוס
                  </v-btn>
                  
                  <v-btn
                    color="#062a4e"
                    dark
                    @click="createOrRemoveFavoritesKashruyot('create')"
                    rounded
                  >
                    <v-icon left small>mdi-content-save</v-icon>
                    שמור
                  </v-btn>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>
  </template>
  
  <script>
  import ApiServices from '@/services/api.service'
  import Constants from '@/util/GetConstant'
  
  export default {
    data() {
      return {
        message: "",
        message2: "",
        activeTab: 0,
        adminSettings: true,
        newCityOrCountryHe: "",
        newCityOrCountryEn: "",
        selectedOption: "city",
        selectedKashruyot: [],
        kashruyotOptions: {},
        lang: this.$i18n.locale,
      }
    },
    computed: {
      // Convert the kashruyotOptions object into an array for the v-select
      formattedKashruyot() {
        return Object.keys(this.kashruyotOptions).map(key => ({
          key: key,  // The dynamic key name (e.g., 'badatz_mehadrin_rubin')
          ...this.kashruyotOptions[key] // Merge the content (he and en) into the object
        }));
      }
    },
    methods: {
      async cleanCache() {
        try {
          let api = process.env.VUE_APP_BASE_URL + "/admin/settings_clean_cache";
          const token = localStorage.getItem("token");
          const res = await fetch(api, ApiServices.requestOptions("POST", "", token));
          const jsonObject = await res.json();
          if (res.status >= 400) {
            this.message = "שגיאה!";
            this.hideMessage('message');
          } else if (res.status === 200) {
            this.message = "בוצע בהצלחה";
            this.hideMessage('message');
          }
        } catch (error) {
          this.message = error.message;
          this.hideMessage('message');
        }
      },
      async addCityOrCountry() {
        try {
          if (this.$refs.form.validate()) {
            let api = process.env.VUE_APP_BASE_URL + "/admin/settings_add_city_or_country_name";
            const token = localStorage.getItem("token");
            const jsonBody = JSON.stringify({ nameHe: this.newCityOrCountryHe, nameEn: this.newCityOrCountryEn, type: this.selectedOption });
            const res = await fetch(api, ApiServices.requestOptions("POST", jsonBody, token));
            const jsonObject = await res.json();
            if (res.status >= 400) {
              this.message = "שגיאה!";
              this.hideMessage('message');
            } else if (res.status === 200) {
              this.message = "בוצע בהצלחה";
              this.newCityOrCountryHe = "";
              this.newCityOrCountryEn = "";
              this.hideMessage('message');
            }
          }
        } catch (error) {
          this.message = error.message;
          this.hideMessage('message');
        }
      },
      hideMessage(type) {
        setTimeout(() => {
          if (type === 'message') {
            this.message = "";
          } else if (type === 'message2') {
            this.message2 = "";
          } else {
            this.message = "";
            this.message2 = "";
          }
        }, 4000);
      },
      async getConstans() {
        if (this.$store.state.constant?.categories?.restaurant) {
          this.kashruyotOptions = this.$store.state.constant.kashruyot
        } else {
          const constants = await Constants.getConstant();
          if (!constants) {
            this.message2 = "שגיאה: יותר מדי כניסות מהכתובת הזאת, נסה שוב מאוחר יותר";
            this.hideMessage('message2');
            return;
          }
          this.kashruyotOptions = constants.kashruyot;
          this.$store.state.constant = constants;
        }
      },
      async createOrRemoveFavoritesKashruyot(action) {
        try {
          let api = process.env.VUE_APP_BASE_URL + "/user/add_or_remove_favorites_kashruyot";
          const token = localStorage.getItem("token");
          const jsonBody = JSON.stringify({ action, list: this.selectedKashruyot });
          const res = await fetch(api, ApiServices.requestOptions("POST", jsonBody, token));
          if (res.status >= 400) {
            this.message2 = "שגיאה!";
            this.hideMessage('message2');
          } else if (res.status === 200) {
            this.message2 = "בוצע בהצלחה";
            if (action === "remove") {
              this.selectedKashruyot = [];
            }
            this.hideMessage('message2');
          }
        } catch (error) {
          this.message2 = error.message;
          this.hideMessage('message2');
        }
      },
      async getUserFavoritesKashruyot() {
        try {
          let api = process.env.VUE_APP_BASE_URL + "/user/get_user_favorites_kashruyot";
          const token = localStorage.getItem("token");
          const res = await fetch(api, ApiServices.requestOptions("GET", "", token));
          const jsonObject = await res.json();
          if (res.status >= 400) {
            this.message2 = "שגיאה!";
            this.hideMessage('message2');
          } else if (res.status === 200) {
            this.selectedKashruyot = jsonObject.favorites_kashruyot;
          }
        } catch (error) {
          this.message2 = error.message;
          this.hideMessage('message2');
        }
      }
    },
    mounted() {
      this.getConstans();
      this.getUserFavoritesKashruyot();
    },
  }
  </script>
  
  <style scoped>
  .settings-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 16px 64px;
  }
  
  /* Override Vuetify styles for better RTL support */
  :deep(.v-text-field__slot) {
    direction: rtl;
  }
  
  :deep(.v-label) {
    right: 12px;
    left: auto;
  }
  
  :deep(.v-input__prepend-inner) {
    margin-right: 0;
    margin-left: 8px;
  }
  
  :deep(.v-text-field--filled .v-label) {
    right: 12px;
    left: auto;
  }
  
  :deep(.v-text-field--filled .v-input__prepend-inner) {
    margin-right: 12px;
    margin-left: 0;
  }
  
  :deep(.v-select__selections) {
    padding-right: 12px;
  }
  
  :deep(.v-chip) {
    margin: 2px;
  }
  
  :deep(.v-messages) {
    text-align: right;
  }
  
  .section-title {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 1rem;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid #dae1eb;
  }
  
  .section-subtitle {
    font-size: 1.1rem;
    font-weight: 500;
  }
  </style>