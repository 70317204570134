<template>
    <v-app style="background-color: #f3f6f9">
            <LogsComp />
    </v-app>
</template>
<script>
import Auth from '@/services/auth.service.js'
import LogsComp from '@/components/admin/logs/LogsComp.vue'

export default {
    components: {
        LogsComp,
    },
    data() {
        return {
        };
    },
    methods: {
    },
    computed: {
    },
    mounted() {
        document.title = 'לוגים | כשר בסביבה'
        let auth = Auth.checkAuth();
        if (!auth) {
            return this.$router.replace({ name: "login" })
        }
    },
}
</script>
<style>
</style>