<template>
    <div class="log-viewer">
      <!-- Header -->
      <div style="background-color: #dae1eb; display: flex; justify-content: space-around; align-items: center;">
        <div ></div>
        <div >
          <v-icon left>mdi-text-box-search</v-icon>
          System Logs
        </div>
        <v-btn icon @click="refreshLogs">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
    </div>
  
      <!-- Filters Section -->
      <v-card flat class="filters-section pa-4">
        <v-row align="center" no-gutters>
          <v-col cols="12" sm="6" md="3" class="px-2">
            <v-menu
              v-model="dateMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="dateFormatted"
                  label="Date"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-on="on"
                  dense
                  outlined
                  hide-details
                  class="mb-3 mb-sm-0"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dateLogs"
                no-title
                @input="dateMenu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
  
          <v-col cols="12" sm="6" md="3" class="px-2">
            <v-select
              v-model="filterLogs"
              :items="logsType"
              label="Log Type"
              prepend-icon="mdi-filter-variant"
              dense
              outlined
              hide-details
              class="mb-3 mb-sm-0"
            ></v-select>
          </v-col>
  
          <v-col cols="12" sm="6" md="3" class="px-2" v-if="filterLogs === 'פניות לשרת'">
            <v-select
              v-model="filterPlatform"
              :items="platformTypes"
              label="Platform"
              prepend-icon="mdi-devices"
              dense
              outlined
              hide-details
              class="mb-3 mb-sm-0"
            ></v-select>
          </v-col>
  
          <v-col cols="12" sm="6" md="3" class="px-2">
            <v-text-field
              v-model="search"
              label="Search"
              prepend-icon="mdi-magnify"
              dense
              outlined
              hide-details
              clearable
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card>
  
      <!-- Stats Bar -->
      <v-card flat class="stats-bar pa-2" color="grey lighten-4">
        <v-row align="center" no-gutters>
          <v-col cols="auto" class="px-4">
            <span class="text-subtitle-2">Total Logs: {{ filteredLogs.length }}</span>
          </v-col>
          <v-col cols="auto" class="px-4">
            <v-chip small :color="getStatColor('info')" label>
              Info: {{ getLogTypeCount('info:') }}
            </v-chip>
          </v-col>
          <v-col cols="auto" class="px-4">
            <v-chip small :color="getStatColor('error')" label>
              Errors: {{ getLogTypeCount('error:') }}
            </v-chip>
          </v-col>
          <v-col cols="auto" class="px-4">
            <v-chip small :color="getStatColor('http')" label>
              HTTP: {{ getLogTypeCount('http:') }}
            </v-chip>
          </v-col>
        </v-row>
      </v-card>
  
      <!-- Logs List -->
      <v-card class="logs-container">
        <v-progress-linear
          :active="progressShow"
          :indeterminate="progressShow"
          color="primary"
        ></v-progress-linear>
        
        <recycle-scroller
          class="logs-scroller"
          :items="filteredLogs"
          :item-size="80"
          key-field="id"
          v-slot="{ item }"
        >
          <v-card
            flat
            :class="['log-entry pa-3', getLevelClass(item.level)]"
            hover
          >
            <div class="d-flex align-center">
              <div class="log-timestamp grey--text text--darken-1">
                {{ item.date }} {{ item.time }}
              </div>
              <v-chip
                x-small
                :color="getLevelColor(item.level)"
                text-color="white"
                class="mx-2"
              >
                {{ item.level.replace(':', '') }}
              </v-chip>
            </div>
            <div class="log-message mt-2" v-html="formatLogMessage(item.message)"></div>
          </v-card>
        </recycle-scroller>
      </v-card>
  
      <!-- Snackbar -->
      <v-snackbar
        v-model="snackbar"
        :color="snackbarColorBt"
        :timeout="3000"
        top
      >
        {{ snacbarText }}
        <template v-slot:action="{ attrs }">
          <v-btn
            text
            v-bind="attrs"
            @click="snackbar = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </div>
  </template>
  
  <script>
  import { format } from 'date-fns';
  import { RecycleScroller } from 'vue-virtual-scroller';
  import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';
  
  export default {
    name: 'LogViewer',
    components: {
      RecycleScroller
    },
    data: () => ({
      overlay: false,
      dateMenu: false,
      snackbar: false,
      snackbarColorBt: "success",
      snacbarText: "",
      progressShow: false,
      logs: [],
      dateLogs: new Date().toISOString().substr(0, 10),
      filterLogs: "הכל",
      filterPlatform: "הכל",
      logsType: ["הכל", "פניות לשרת", "שגיאות", "מידע"],
      platformTypes: ["הכל", "אתר", "אפליקציה"],
      search: ""
    }),
  
    computed: {
      dateFormatted() {
        return this.dateLogs ? format(new Date(this.dateLogs), 'MMM dd, yyyy') : '';
      },
      filteredLogs() {
        let filtered = this.logs;
  
        if (this.filterLogs === "שגיאות") {
          filtered = filtered.filter(log => 
            log.level === "error:" || log.level === "exception:"
          );
        } else if (this.filterLogs === "פניות לשרת") {
          filtered = filtered.filter(log => log.level === "http:");
          if (this.filterPlatform === "אתר") {
            filtered = filtered.filter(log => 
              log.message.includes("website")
            );
          }
          if (this.filterPlatform === "אפליקציה") {
            filtered = filtered.filter(log => 
              log.message.includes("app")
            );
          }
        } else if (this.filterLogs === "מידע") {
          filtered = filtered.filter(log => 
            log.level === "info:"
          );
        }
  
        if (this.search) {
          const searchLower = this.search.toLowerCase();
          filtered = filtered.filter(item => 
            Object.values(item).some(value => 
              value && value.toString().toLowerCase().includes(searchLower)
            )
          );
        }
  
        return filtered;
      }
    },
  
    methods: {
      async getLogs() {
        try {
          this.progressShow = true;
          let token = localStorage.getItem("token");
          let api = process.env.VUE_APP_BASE_URL + `/logs/get_today_logs?date=${this.dateLogs}`;
          const res = await fetch(api, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          });
          
          const jsonObject = await res.json();
          
          if (res.status >= 400) {
            this.showSnackBar("Error fetching logs", "error");
          } else if (res.status === 200 && jsonObject) {
            this.logs = jsonObject.split('\n')
              .filter(line => line.trim())
              .map((line, index) => {
                const parts = line.split(' ');
                return {
                  id: index,
                  date: parts[0],
                  time: parts[1],
                  level: parts[2],
                  message: parts.slice(3).join(' ')
                };
              });
          }
        } catch (error) {
          this.showSnackBar(`Error: ${error.message}`, "error");
        } finally {
          this.progressShow = false;
        }
      },
  
      formatLogMessage(message) {
        const keywords = /\b(SessionId|PLATFORM|IP|ROUTE|REST_ID|KEY|VALUE|COUNTRY|http:)\b/g;
        return message.replace(keywords, '<span class="highlight">$1</span>');
      },
  
      getLevelClass(level) {
        return {
          'log-entry--info': level === 'info:',
          'log-entry--error': level === 'error:' || level === 'exception:',
          'log-entry--http': level === 'http:'
        };
      },
  
      getLevelColor(level) {
        switch(level) {
          case 'info:': return 'success';
          case 'error:': 
          case 'exception:': return 'error';
          case 'http:': return 'primary';
          default: return 'grey';
        }
      },
  
      getStatColor(type) {
        switch(type) {
          case 'info': return 'success lighten-3';
          case 'error': return 'error lighten-3';
          case 'http': return 'primary lighten-3';
          default: return 'grey lighten-3';
        }
      },
  
      getLogTypeCount(level) {
        return this.logs.filter(log => log.level === level).length;
      },
  
      refreshLogs() {
        this.getLogs();
      },
  
      showSnackBar(message, color) {
        this.snacbarText = message;
        this.snackbarColorBt = color;
        this.snackbar = true;
      }
    },
  
    watch: {
      dateLogs: {
        handler() {
          this.getLogs();
        }
      }
    },
  
    created() {
      this.getLogs();
    }
  }
  </script>
  
  <style lang="scss" scoped>
  .log-viewer {
    height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: #f5f5f5;
  
    .logs-header {
      position: sticky;
      top: 0;
      z-index: 2;
    }
  
    .filters-section {
      background-color: white;
      border-bottom: 1px solid #e0e0e0;
    }
  
    .stats-bar {
      border-bottom: 1px solid #e0e0e0;
    }
  
    .logs-container {
      flex: 1;
      overflow: hidden;
      position: relative;
    }
  
    .logs-scroller {
      height: calc(100vh - 250px);
    }
  
    .log-entry {
      border-bottom: 1px solid #f0f0f0;
      transition: background-color 0.2s ease;
  
      &:hover {
        background-color: #f8f9fa;
      }
  
      &--info {
        border-left: 4px solid #4caf50;
      }
  
      &--error {
        border-left: 4px solid #f44336;
      }
  
      &--http {
        border-left: 4px solid #2196f3;
      }
    }
  
    .log-timestamp {
      font-family: monospace;
      font-size: 0.9rem;
    }
  
    .log-message {
      font-family: 'Roboto Mono', monospace;
      white-space: pre-wrap;
      word-break: break-word;
  
      .highlight {
        font-weight: 600;
        color: #1976d2;
        background-color: #e3f2fd;
        padding: 0 4px;
        border-radius: 2px;
      }
    }
  }

  .log-entry {
  direction: ltr !important; /* Force Left-to-Right */
  text-align: left !important; /* Ensure text alignment is left */
}

.log-message {
  direction: ltr !important; /* Ensures text within messages stays LTR */
  unicode-bidi: plaintext; /* Keeps mixed language text readable */
  text-align: left !important;
  white-space: pre-wrap;
  word-break: break-word;
  overflow-wrap: break-word;
}
  </style>