<template>
  <div class="add-restaurant-container">
    <v-card class="rounded-card card-shadow" elevation="0">
      <v-card-title class="d-flex justify-center pt-6 pb-2">
        <h1 class="text-h4 primary-color font-weight-bold">הוספת מסעדה</h1>
      </v-card-title>

      <v-card-text class="form-container">
        <v-container>
          <v-form @submit.prevent="submitHandler" ref="form">
            <v-row v-if="itemEdited.zone_web">
              <!-- Basic Information Section -->
              <v-col cols="12">
                <div class="section-title primary-color">מידע בסיסי</div>
              </v-col>

              <v-col cols="12" sm="6">
                <v-text-field v-model="itemEdited.name" label="שם המסעדה" filled rounded dense
                  :rules="[v => !!v || 'שדה חובה']" prepend-inner-icon="mdi-store" required></v-text-field>
              </v-col>

              <v-col cols="12" sm="6">
                <v-text-field v-model="itemEdited.address" label="רחוב" filled rounded dense
                  :rules="[v => !!v || 'שדה חובה']" prepend-inner-icon="mdi-map-marker" required></v-text-field>
              </v-col>

              <v-col cols="12" sm="4">
                <v-autocomplete :items="citiesItems" v-model="itemEdited.city_web" label="עיר" filled rounded dense
                  :rules="[v => !!v || 'שדה חובה']" prepend-inner-icon="mdi-city" required></v-autocomplete>
              </v-col>

              <v-col cols="12" sm="4">
                <v-autocomplete :items="countriesItems" v-model="itemEdited.country_web" label="מדינה (רק בחול)" filled
                  rounded dense prepend-inner-icon="mdi-earth"></v-autocomplete>
              </v-col>

              <v-col cols="12" sm="4">
                <v-autocomplete :items="zonesItems" v-model="itemEdited.zone_web" label="אזור" filled rounded dense
                  :rules="[v => !!v || 'שדה חובה']" prepend-inner-icon="mdi-map" required></v-autocomplete>
              </v-col>

              <v-col cols="12" sm="6">
                <v-text-field v-model="itemEdited.website" label="אתר" filled rounded dense
                  prepend-inner-icon="mdi-web"></v-text-field>
              </v-col>

              <v-col cols="12" sm="6">
                <v-text-field v-model="itemEdited.phone" @input="filterNumeric" label="טלפון" filled rounded dense
                  prepend-inner-icon="mdi-phone"></v-text-field>
              </v-col>

              <!-- Categories Section -->
              <v-col cols="12">
                <div class="section-title primary-color">קטגוריות</div>
              </v-col>

              <v-col cols="12" sm="4">
                <v-autocomplete :items="categoriesItems" v-model="itemEdited.category_web" label="קטגוריה" filled
                  rounded dense :rules="[v => !!v || 'שדה חובה']" prepend-inner-icon="mdi-tag"
                  required></v-autocomplete>
              </v-col>

              <v-col cols="12" sm="4">
                <v-autocomplete :items="subCategoriesItems" multiple v-model="itemEdited.sub_category_web"
                  label="תת קטגוריה" filled rounded dense prepend-inner-icon="mdi-tag-multiple" chips
                  small-chips></v-autocomplete>
              </v-col>

              <v-col cols="12" sm="4">
                <v-select :items="typesItems" multiple v-model="itemEdited.type_web" label="סוג" filled rounded dense
                  :rules="[v => !!v || 'שדה חובה']" prepend-inner-icon="mdi-format-list-bulleted-type" chips small-chips
                  required></v-select>
              </v-col>

              <v-col cols="12">
                <v-textarea v-model="itemEdited.details" label="מידע על המסעדה" filled rounded dense auto-grow rows="2"
                  prepend-inner-icon="mdi-information-outline"></v-textarea>
              </v-col>

              <!-- Kashrut Section -->
              <v-col cols="12">
                <div class="section-title primary-color">כשרות</div>
              </v-col>

              <v-col cols="12" sm="4">
                <v-autocomplete :items="kashrutItems" v-model="itemEdited.kashrut.kashrut_name_web" label="כשרות" filled
                  rounded dense :rules="[v => !!v || 'שדה חובה']" prepend-inner-icon="mdi-check-circle"
                  required></v-autocomplete>
              </v-col>

              <v-col cols="12" sm="4" v-if="itemEdited.kashrut.kashrut_name_web === 'badatz_local'">
                <v-text-field v-model="itemEdited.kashrut.local_kashrut_name" label="כשרות מקומית" filled rounded dense
                  prepend-inner-icon="mdi-check-decagram"></v-text-field>
              </v-col>

              <v-col cols="12" sm="4">
                <v-text-field v-model="itemEdited.kashrut.kashrut_details" label="פרטים נוספים על הכשרות" filled rounded
                  dense prepend-inner-icon="mdi-information"></v-text-field>
              </v-col>

              <!-- Opening Hours Section -->
              <v-col cols="12">
                <div class="section-title primary-color">שעות פתיחה</div>
              </v-col>

              <v-col cols="12">
                <v-row>
                  <v-col cols="12" sm="6" md="3" lg="3" xl="3" class="pa-2">
                    <v-card class="pa-3 rounded-card" outlined>
                      <div class="text-subtitle-1 font-weight-medium mb-2">יום א'</div>
                      <v-row dense>
                        <v-col cols="6">
                          <v-text-field v-model="itemEdited.open_hours.sunday.open" type="time" label="פתיחה" filled
                            rounded dense></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field v-model="itemEdited.open_hours.sunday.close" type="time" label="סגירה" filled
                            rounded dense></v-text-field>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>

                  <v-col cols="12" sm="6" md="3" lg="3" xl="3" class="pa-2">
                    <v-card class="pa-3 rounded-card" outlined>
                      <div class="text-subtitle-1 font-weight-medium mb-2">יום ב'</div>
                      <v-row dense>
                        <v-col cols="6">
                          <v-text-field v-model="itemEdited.open_hours.monday.open" type="time" label="פתיחה" filled
                            rounded dense></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field v-model="itemEdited.open_hours.monday.close" type="time" label="סגירה" filled
                            rounded dense></v-text-field>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>

                  <v-col cols="12" sm="6" md="3" lg="3" xl="3" class="pa-2">
                    <v-card class="pa-3 rounded-card" outlined>
                      <div class="text-subtitle-1 font-weight-medium mb-2">יום ג'</div>
                      <v-row dense>
                        <v-col cols="6">
                          <v-text-field v-model="itemEdited.open_hours.tuesday.open" type="time" label="פתיחה" filled
                            rounded dense></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field v-model="itemEdited.open_hours.tuesday.close" type="time" label="סגירה" filled
                            rounded dense></v-text-field>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>

                  <v-col cols="12" sm="6" md="3" lg="3" xl="3" class="pa-2">
                    <v-card class="pa-3 rounded-card" outlined>
                      <div class="text-subtitle-1 font-weight-medium mb-2">יום ד'</div>
                      <v-row dense>
                        <v-col cols="6">
                          <v-text-field v-model="itemEdited.open_hours.wednesday.open" type="time" label="פתיחה" filled
                            rounded dense></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field v-model="itemEdited.open_hours.wednesday.close" type="time" label="סגירה" filled
                            rounded dense></v-text-field>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>

                  <v-col cols="12" sm="6" md="3" lg="3" xl="3" class="pa-2">
                    <v-card class="pa-3 rounded-card" outlined>
                      <div class="text-subtitle-1 font-weight-medium mb-2">יום ה'</div>
                      <v-row dense>
                        <v-col cols="6">
                          <v-text-field v-model="itemEdited.open_hours.thursday.open" type="time" label="פתיחה" filled
                            rounded dense></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field v-model="itemEdited.open_hours.thursday.close" type="time" label="סגירה" filled
                            rounded dense></v-text-field>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>

                  <v-col cols="12" sm="6" md="3" lg="3" xl="3" class="pa-2">
                    <v-card class="pa-3 rounded-card" outlined>
                      <div class="text-subtitle-1 font-weight-medium mb-2">יום ו'</div>
                      <v-row dense>
                        <v-col cols="6">
                          <v-text-field v-model="itemEdited.open_hours.friday.open" type="time" label="פתיחה" filled
                            rounded dense></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field v-model="itemEdited.open_hours.friday.close" type="time" label="סגירה" filled
                            rounded dense></v-text-field>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>

                  <v-col cols="12" sm="6" md="3" lg="3" xl="3" class="pa-2">
                    <v-card class="pa-3 rounded-card" outlined>
                      <div class="text-subtitle-1 font-weight-medium mb-2">מוצ"ש</div>
                      <v-row dense>
                        <v-col cols="6">
                          <v-text-field v-model="itemEdited.open_hours.saturday.open" type="time" label="פתיחה" filled
                            rounded dense></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field v-model="itemEdited.open_hours.saturday.close" type="time" label="סגירה" filled
                            rounded dense></v-text-field>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12" style="display: flex;">
                <v-checkbox v-model="itemEdited.open_hours.only_weekend" :label="weekendTitle"
                  style="margin-bottom: 0px !important; margin-top: 0px !important;">
                </v-checkbox>
                <v-checkbox v-model="itemEdited.open_hours.isTemporarilyClosed" :label="tempClosedTitle"
                  style="margin-bottom: 0px !important; margin-top: 0px !important; margin-right: 20px;">
                </v-checkbox>
              </v-col>

              <!-- Location Section -->
              <v-col cols="12">
                <div class="section-title primary-color">מיקום</div>
              </v-col>

              <v-col cols="12" sm="6">
                <v-text-field v-model="itemEdited.location.latitude" label="קו רוחב (Latitude)" filled rounded dense
                  :rules="[v => !!v || 'שדה חובה']" prepend-inner-icon="mdi-latitude" required></v-text-field>
              </v-col>

              <v-col cols="12" sm="6">
                <v-text-field v-model="itemEdited.location.longitude" label="קו אורך (Longitude)" filled rounded dense
                  :rules="[v => !!v || 'שדה חובה']" prepend-inner-icon="mdi-longitude" required></v-text-field>
              </v-col>

              <v-col cols="12" class="d-flex flex-wrap align-center">
                <v-btn @click="chooseDeviceLocation()" color="#062a4e" dark class="mr-3 mb-3" rounded>
                  <v-icon left>mdi-crosshairs-gps</v-icon>
                  בחר את מיקום המכשיר
                </v-btn>

                <div class="text-body-2 grey--text text--darken-1 mb-3">
                  <v-icon small color="grey darken-1">mdi-information-outline</v-icon>
                  או בחר מיקום מהמפה
                </div>
              </v-col>

              <v-col cols="12">
                <!-- Search Field -->
                <div class="map-search-input">
                  <GmapPlaceInput @place_changed="setPlace" ref="gmapInput" class="elevation-1 rounded-lg" />
                </div>

                <!-- Google Maps -->
                <div class="map-container">
                  <GmapMap ref="gmapRef" :center="center" :zoom="12" style="width: 100%; height: 400px"
                    @click="mapClicked">
                    <GmapMarker v-if="marker.position.lat && marker.position.lng" :position="marker.position"
                      @click="markerClicked"></GmapMarker>
                  </GmapMap>
                </div>
              </v-col>

              <v-col cols="12" class="text-center mt-4">
                <v-btn color="#062a4e" dark @click="addRestaurant()" rounded x-large min-width="180"
                  :loading="progressShow">
                  <v-icon left>mdi-plus</v-icon>
                  הוסף מסעדה
                </v-btn>
              </v-col>
            </v-row>
          </v-form>

          <!-- <v-row v-if="progressShow" justify="center" align="center" class="my-8">
              <v-progress-circular indeterminate color="#3F51B5" size="64"></v-progress-circular>
            </v-row> -->
        </v-container>
      </v-card-text>
    </v-card>

    <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>
  </div>
</template>

<script>
import ApiServices from '@/services/api.service'
import SnackBar from '@/components/widgets/snackBar'
import Constants from '@/util/GetConstant'

export default {
  components: {
    SnackBar
  },
  data() {
    return {
      progressShow: false,
      snackbar: false,
      snackbarColorBt: "green",
      snacbarText: "",
      itemEdited: {},
      objKashruyot: {},
      objTypes: {},
      objCategories: {},
      objSubCategories: {},
      objZones: {},
      objCountries: {},
      objCities: {},
      weekendTitle: "פתוח רק בסופ\"ש",
      tempClosedTitle: "סגור זמנית",
      center: { lat: 32.0879976, lng: 34.8384455 },
      marker: {
        position: { lat: 32.0879976, lng: 34.8384455 }
      }
    }
  },
  methods: {
    async addRestaurant() {
      if (this.$refs.form.validate()) {
        try {
          this.progressShow = true;
          let token = localStorage.getItem("token");
          const restaurantJSON = JSON.stringify({ rest: this.itemEdited })
          let api = process.env.VUE_APP_BASE_URL + `/admin/add_restaurant`;
          const res = await fetch(api, ApiServices.requestOptions("POST", restaurantJSON, token));
          this.progressShow = false;
          const jsonObject = await res.json();
          if (res.status >= 400) {
            this.showSnackBar("שגיאה בהוספת מסעדה : " + jsonObject.message, "red");
          } else if (res.status === 200) {
            this.initializeEmptyObj();
            this.showSnackBar("המסעדה נוספה בהצלחה!", "green");
          }
        } catch (error) {
          this.progressShow = false;
          this.showSnackBar("Error save new restaurant: " + error, "red");
        }
      }
    },
    updateMarkerPosition() {
      const latitude = parseFloat(this.itemEdited.location.latitude);
      const longitude = parseFloat(this.itemEdited.location.longitude);

      // Ensure the latitude and longitude are valid numbers
      if (isFinite(latitude) && isFinite(longitude)) {
        // Update marker position with valid latitude and longitude
        this.marker.position = {
          lat: latitude,
          lng: longitude
        };

        // Optionally, move the map to center on the new marker position
        this.$refs.gmapRef.$mapObject.panTo(this.marker.position);
      } else {
        console.error("Invalid latitude or longitude values:", latitude, longitude);
      }
    },
    chooseDeviceLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          position => {
            this.showPosition(position);
            { true }
          },
          error => {
            this.showSnackBar("שגיאה בקבלת המיקום: יש להפעיל את המיקום בלחיצה על האייקון למעלה בשורת הכתובת", "red");
          }
        );
      } else {
        this.showSnackBar("Geolocation is not supported", "red");
      }
    },
    showPosition(position) {
      this.itemEdited.location.latitude = position.coords.latitude;
      this.itemEdited.location.longitude = position.coords.longitude;

      // Update marker position
      this.marker.position = {
        lat: position.coords.latitude,
        lng: position.coords.longitude
      };

      // Update map center
      this.center = this.marker.position;
    },
    showSnackBar(message, color) {
      this.snacbarText = message;
      this.snackbarColorBt = color;
      this.snackbar = true
    },
    async getConstants() {
      const constants = await Constants.getConstant();
      if (!constants) {
        this.showSnackBar("שגיאה: יותר מדי כניסות מהכתובת הזאת, נסה שוב מאוחר יותר", "red");
      }
      this.objKashruyot = constants.kashruyot
      this.objTypes = constants.types;
      this.objCategories = constants.categories;
      this.objSubCategories = constants.sub_categories;
      this.objZones = constants.zones
      this.objCountries = constants.countries
      this.objCities = constants.cities
    },
    initializeEmptyObj() {
      this.itemEdited = {
        name: "",
        address: "",
        city: "",
        city_web: "",
        country: "",
        country_web: "",
        zone: "",
        zone_web: "israel",
        type: [],
        type_web: [],
        phone: "",
        website: "",
        category: "",
        category_web: "",
        sub_category: [],
        sub_category_web: [],
        details: "",
        pictures: [],
        logo: null,
        kashrut: { kashrut_name: "", kashrut_name_web: "", local_kashrut_name: "", kashrut_details: "", certificate: null },
        open_hours: {
          sunday: { open: "", close: "" }, monday: { open: "", close: "" }, tuesday: { open: "", close: "" },
          wednesday: { open: "", close: "" }, thursday: { open: "", close: "" }, friday: { open: "", close: "" }, saturday: { open: "", close: "" },
          only_weekend: false,
        },
        location: { latitude: "", longitude: "" }
      }
    },
    setPlace(place) {
      this.marker.position = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng()
      };
      this.center = this.marker.position;

      // Update location in itemEdited
      this.itemEdited.location.latitude = place.geometry.location.lat();
      this.itemEdited.location.longitude = place.geometry.location.lng();
    },
    markerClicked(event) {
      // Instead of alert, show a snackbar with the coordinates
      this.showSnackBar(`מיקום נבחר: ${event.latLng.lat().toFixed(6)}, ${event.latLng.lng().toFixed(6)}`, "blue");
    },
    mapClicked(event) {
      this.marker.position = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng()
      };

      this.itemEdited.location.latitude = event.latLng.lat();
      this.itemEdited.location.longitude = event.latLng.lng();
    },
    filterNumeric(value) {
      // Replace non-numeric characters with an empty string
      this.itemEdited.phone = value.replace(/\D+/g, '');
    }
  },
  computed: {
    kashrutItems() {
      return Object.entries(this.objKashruyot).map(([key, value]) => ({
        value: key, // You can use the key as the value
        text: value[this.$i18n.locale] // or value.en for English
      }));
    },
    zonesItems() {
      return Object.entries(this.objZones).map(([key, value]) => ({
        value: key, // You can use the key as the value
        text: value[this.$i18n.locale] // or value.en for English
      }));
    },
    typesItems() {
      return Object.entries(this.objTypes).map(([key, value]) => ({
        value: key, // You can use the key as the value
        text: value[this.$i18n.locale] // or value.en for English
      }));
    },
    categoriesItems() {
      return Object.entries(this.objCategories).map(([key, value]) => ({
        value: key, // You can use the key as the value
        text: value[this.$i18n.locale] // or value.en for English
      }));
    },
    subCategoriesItems() {
      return Object.entries(this.objSubCategories).map(([key, value]) => ({
        value: key, // You can use the key as the value
        text: value[this.$i18n.locale] // or value.en for English
      }));
    },
    countriesItems() {
      return Object.entries(this.objCountries).map(([key, value]) => ({
        value: key, // You can use the key as the value
        text: value[this.$i18n.locale] // or value.en for English
      }));
    },
    citiesItems() {
      return Object.entries(this.objCities).map(([key, value]) => ({
        value: key, // You can use the key as the value
        text: value[this.$i18n.locale] // or value.en for English
      }));
    },
  },
  mounted() {
    this.getConstants();
    this.initializeEmptyObj();

    // change maps input style
    this.$nextTick(() => {
      const input = this.$refs.gmapInput.$el.querySelector('input');
      if (input) {
        input.style.backgroundColor = '#f5f7fa';
        input.style.padding = '12px 16px';
        input.style.border = '1px solid #dae1eb';
        input.style.borderRadius = '8px';
        input.style.width = '100%';
        input.style.maxWidth = '400px';
        input.style.fontSize = '14px';
      }
    });
  },
  watch: {
    'itemEdited.location.latitude': function (newLat) {
      this.updateMarkerPosition();
    },
    'itemEdited.location.longitude': function (newLng) {
      this.updateMarkerPosition();
    }
  }
}
</script>

<style scoped>
.add-restaurant-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 16px 64px;
}

/* Override Vuetify styles for better RTL support */
:deep(.v-text-field__slot) {
  direction: rtl;
}

:deep(.v-label) {
  right: 12px;
  left: auto;
}

:deep(.v-input__prepend-inner) {
  margin-right: 0;
  margin-left: 8px;
}

:deep(.v-text-field--filled .v-label) {
  right: 12px;
  left: auto;
}

:deep(.v-text-field--filled .v-input__prepend-inner) {
  margin-right: 12px;
  margin-left: 0;
}

:deep(.v-select__selections) {
  padding-right: 12px;
}

:deep(.v-chip) {
  margin: 2px;
}

:deep(.v-messages) {
  text-align: right;
}

.image-upload-container {
  border: 2px dashed #dae1eb;
  border-radius: 8px;
  background-color: #f5f7fa;
  padding: 16px;
  text-align: center;
  transition: all 0.3s ease;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.image-upload-container:hover {
  border-color: #3f51b5;
  background-color: #f0f3f8;
}

.image-preview-container {
  position: relative;
  width: 100%;
  height: 150px;
  background-color: #f5f7fa;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid #dae1eb;
}

.image-wrapper {
  width: 100%;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.image-preview {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.delete-icon {
  position: absolute;
  top: 8px;
  right: 8px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  padding: 4px;
  cursor: pointer;
  z-index: 10;
  color: #f44336;
}

.map-container {
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  margin-top: 16px;
}

.map-search-input {
  margin-bottom: 16px;
  width: 100%;
  max-width: 400px;
}

.section-title {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #dae1eb;
}
</style>