<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="6" lg="4">
        <v-card class="elevation-12 rounded-lg">
          <v-card-title class="headline primary white--text py-4 text-center">
            <v-icon large color="white" class="mr-2">mdi-lock-reset</v-icon>
            איפוס סיסמה
          </v-card-title>

          <v-card-text class="pt-6">
            <v-form ref="form" v-model="valid" @submit.prevent="update">
              <v-text-field
                v-model="ressetPass.newPassword"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                label="סיסמה חדשה"
                name="password"
                prepend-icon="mdi-lock"
                :rules="passwordRules"
                @click:append="showPassword = !showPassword"
                counter
                outlined
                dense
              ></v-text-field>

              <v-text-field
                v-model="ressetPass.againPassword"
                :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showConfirmPassword ? 'text' : 'password'"
                label="אימות סיסמה"
                name="confirmPassword"
                prepend-icon="mdi-lock-check"
                :rules="confirmPasswordRules"
                @click:append="showConfirmPassword = !showConfirmPassword"
                counter
                outlined
                dense
              ></v-text-field>

              <v-card-text class="pa-0 mb-4">
                <v-list-item dense class="pa-0">
                  <v-list-item-content>
                    <v-list-item-subtitle>
                      הסיסמה חייבת להכיל:
                    </v-list-item-subtitle>
                    <v-list-item-subtitle class="d-flex align-center" :class="{'success--text': hasMinLength}">
                      <v-icon small :color="hasMinLength ? 'success' : ''" class="mr-1">
                        {{ hasMinLength ? 'mdi-check-circle' : 'mdi-circle-outline' }}
                      </v-icon>
                      לפחות 9 תווים
                    </v-list-item-subtitle>
                    <v-list-item-subtitle class="d-flex align-center" :class="{'success--text': hasMatch}">
                      <v-icon small :color="hasMatch ? 'success' : ''" class="mr-1">
                        {{ hasMatch ? 'mdi-check-circle' : 'mdi-circle-outline' }}
                      </v-icon>
                      סיסמאות תואמות
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-card-text>
            </v-form>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions class="pa-4">
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              :loading="showSpinner"
              :disabled="!valid || showSpinner"
              large
              @click="update"
            >
              <v-icon left>mdi-content-save</v-icon>
              שמור סיסמה
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-snackbar
      v-model="snackbar"
      :color="snackbarColorBt"
      :timeout="5000"
      top
      right
    >
      {{ snacbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          סגור
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import ApiServices from '@/services/api.service'

export default {
  name: 'PasswordReset',
  
  data() {
    return {
      valid: false,
      showSpinner: false,
      snackbar: false,
      snackbarColorBt: "success",
      snacbarText: "",
      showPassword: false,
      showConfirmPassword: false,
      ressetPass: {
        newPassword: "",
        againPassword: "",
      },
      passwordRules: [
        v => !!v || 'סיסמה היא שדה חובה',
        v => v.length >= 9 || 'סיסמה חייבת להכיל לפחות 9 תווים'
      ],
      confirmPasswordRules: [
        v => !!v || 'אימות סיסמה הוא שדה חובה',
        v => v === this.ressetPass.newPassword || 'הסיסמאות אינן תואמות'
      ]
    }
  },

  computed: {
    hasMinLength() {
      return this.ressetPass.newPassword.length >= 9;
    },
    hasMatch() {
      return this.ressetPass.newPassword === this.ressetPass.againPassword 
        && this.ressetPass.newPassword.length > 0;
    }
  },

  methods: {
    async update() {
      if (!this.$refs.form.validate()) return;

      this.showSpinner = true;
      const payload = JSON.stringify({ newPassword: this.ressetPass.newPassword });
      const token = this.$route.params.token;

      try {
        const api = process.env.VUE_APP_BASE_URL + "/auth/resetPassword";
        const res = await fetch(api, ApiServices.requestOptions("PUT", payload, token));
        
        if (res.status >= 400) {
          const jsonObject = await res.json();
          this.showSnackBar("שגיאה בשמירת הסיסמה: " + jsonObject.errors[0].message, "error");
        } else if (res.status === 200) {
          this.showSnackBar("הסיסמה שונתה בהצלחה", "success");
          await this.sleep(1500);
          this.$router.replace({ name: "login" });
        }
      } catch (error) {
        this.showSnackBar("שגיאה בשמירת הסיסמה: " + error, "error");
      } finally {
        this.showSpinner = false;
      }
    },

    showSnackBar(message, color) {
      this.snacbarText = message;
      this.snackbarColorBt = color;
      this.snackbar = true;
    },

    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }
  }
}
</script>

<style scoped>
.v-card {
  transition: all 0.3s ease-in-out;
}

.v-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 16px rgba(0,0,0,0.1) !important;
}

.headline {
  font-weight: 600;
  letter-spacing: 0.5px;
}
</style>