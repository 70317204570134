var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"verify-email-container",attrs:{"dir":"rtl"}},[(_vm.emailVerified !== 'null')?_c('div',{staticClass:"verification-card"},[(_vm.emailVerified === 'true')?_c('div',{staticClass:"status-container"},[_vm._m(0),_c('h1',{staticClass:"status-title"},[_vm._v("האימות בוצע בהצלחה!")]),_c('p',{staticClass:"status-message"},[_vm._v("תודה שאימתת את כתובת האימייל שלך. מיד תועבר לדף ההתחברות.")])]):(_vm.emailVerified === 'false')?_c('div',{staticClass:"status-container"},[_vm._m(1),_c('h1',{staticClass:"status-title"},[_vm._v("האימות נכשל")]),_c('p',{staticClass:"status-message"},[_vm._v("מצטערים, לא הצלחנו לאמת את כתובת האימייל שלך. אנא נסה שנית או צור קשר עם התמיכה.")])]):_c('div',{staticClass:"status-container"},[_vm._m(2),_c('h1',{staticClass:"status-title"},[_vm._v("החשבון שלך כבר אומת")]),_c('p',{staticClass:"status-message"},[_vm._v("אתה יכול להמשיך להתחבר למערכת.")])]),_c('button',{class:['action-button', {
              'success': _vm.emailVerified === 'true',
              'error': _vm.emailVerified === 'false',
              'verified': _vm.emailVerified === 'alreadyConfirmed'
            }],on:{"click":function($event){return _vm.$router.push({ name: 'login' })}}},[_vm._v(" המשך להתחברות ")])]):_c('div',{staticClass:"loading-container"},[_c('div',{staticClass:"loader"}),_c('p',{staticClass:"loading-text"},[_vm._v("מאמת את כתובת האימייל שלך...")])]),_c('div',{class:['snackbar', { 'show': _vm.snackbar }],style:({ backgroundColor: _vm.snackbarColorBt === 'red' ? '#dc2626' : '#16a34a' })},[_c('span',{staticClass:"snackbar-text"},[_vm._v(_vm._s(_vm.snacbarText))]),_c('button',{staticClass:"snackbar-close",on:{"click":function($event){_vm.snackbar = false}}},[_vm._v(" סגור ")])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon-circle success"},[_c('i',{staticClass:"fas fa-check"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon-circle error"},[_c('i',{staticClass:"fas fa-times"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon-circle verified"},[_c('i',{staticClass:"fas fa-check-double"})])
}]

export { render, staticRenderFns }