import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import he from 'vuetify/lib/locale/he'
import en from 'vuetify/lib/locale/en';
import Auth from '@/services/auth.service.js'; // adjust the import path as needed

Vue.use(Vuetify);

export default new Vuetify({
  // rtl: false, 
  rtl: window.location.href.startsWith('https://en.') ? false : true, // Default language,
  lang: {
    locales: { he, en },
    // current:  'en', // Default language
    current: window.location.href.startsWith('https://en.') ? 'en' : 'he', // Default language
  },
});
