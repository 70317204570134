<template>
    <div class="filter-panels bg-white rounded-lg shadow-lg">
      <!-- Kashrut Filter -->
      <div class="filter-section" :class="{ 'is-open': openSection === 'kashrut' }">
        <button 
          class="filter-header"
          @click="toggleSection('kashrut')"
        >
          <span class="filter-title">{{ $t('home.filter_kashrut_title') }}</span>
          <span class="filter-icon" :class="{ 'rotate-180': openSection === 'kashrut' }">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m6 9 6 6 6-6"/></svg>
          </span>
        </button>
        <div class="filter-content" :class="{ 'expanded': openSection === 'kashrut' }">
          <div v-if="!hasSelectedFilters('kashruyot')" class="show-all">
            {{ $t('home.filter_show_all_title') }}
          </div>
          <label 
            v-for="(label, key) in constants.kashruyot" 
            :key="key"
            class="filter-option"
          >
            <input 
              type="checkbox"
              v-model="constantsChoosed.kashruyot[key]" 
              @change="onCheckboxChange(label, key, 'kashruyot')"
              class="filter-checkbox"
            >
            <span class="checkmark"></span>
            <span class="label-text">{{ label[lang] }}</span>
          </label>
        </div>
      </div>
  
      <!-- Type Filter -->
      <div class="filter-section" :class="{ 'is-open': openSection === 'type' }">
        <button 
          class="filter-header"
          @click="toggleSection('type')"
        >
          <span class="filter-title">{{ $t('home.filter_type_title') }}</span>
          <span class="filter-icon" :class="{ 'rotate-180': openSection === 'type' }">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m6 9 6 6 6-6"/></svg>
          </span>
        </button>
        <div class="filter-content" :class="{ 'expanded': openSection === 'type' }">
          <div v-if="!hasSelectedFilters('types')" class="show-all">
            {{ $t('home.filter_show_all_title') }}
          </div>
          <label 
            v-for="(label, key) in constants.types" 
            :key="key"
            class="filter-option"
          >
            <input 
              type="checkbox"
              v-model="constantsChoosed.types[key]"
              @change="onCheckboxChange(label, key, 'types')"
              class="filter-checkbox"
            >
            <span class="checkmark"></span>
            <span class="label-text">{{ label[lang] }}</span>
          </label>
        </div>
      </div>
  
      <!-- Category Filter -->
      <div class="filter-section" :class="{ 'is-open': openSection === 'category' }">
        <button 
          class="filter-header"
          @click="toggleSection('category')"
        >
          <span class="filter-title">{{ $t('home.filter_category_title') }}</span>
          <span class="filter-icon" :class="{ 'rotate-180': openSection === 'category' }">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m6 9 6 6 6-6"/></svg>
          </span>
        </button>
        <div class="filter-content" :class="{ 'expanded': openSection === 'category' }">
          <div v-if="!hasSelectedFilters('categories')" class="show-all">
            {{ $t('home.filter_show_all_title') }}
          </div>
          <label 
            v-for="(label, key) in constants.categories" 
            :key="key"
            class="filter-option"
          >
            <input 
              type="checkbox"
              v-model="constantsChoosed.categories[key]"
              @change="onCheckboxChange(label, key, 'categories')"
              class="filter-checkbox"
            >
            <span class="checkmark"></span>
            <span class="label-text">{{ label[lang] }}</span>
          </label>
        </div>
      </div>
  
      <!-- Sub Category Filter -->
      <div class="filter-section" :class="{ 'is-open': openSection === 'subcategory' }">
        <button 
          class="filter-header"
          @click="toggleSection('subcategory')"
        >
          <span class="filter-title">{{ $t('home.filter_sub_category_title') }}</span>
          <span class="filter-icon" :class="{ 'rotate-180': openSection === 'subcategory' }">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m6 9 6 6 6-6"/></svg>
          </span>
        </button>
        <div class="filter-content" :class="{ 'expanded': openSection === 'subcategory' }">
          <div v-if="!hasSelectedFilters('sub_categories')" class="show-all">
            {{ $t('home.filter_show_all_title') }}
          </div>
          <label 
            v-for="(label, key) in constants.sub_categories" 
            :key="key"
            class="filter-option"
          >
            <input 
              type="checkbox"
              v-model="constantsChoosed.sub_categories[key]"
              @change="onCheckboxChange(label, key, 'sub_categories')"
              class="filter-checkbox"
            >
            <span class="checkmark"></span>
            <span class="label-text">{{ label[lang] }}</span>
          </label>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'ExpansionPanels',
    props: {
      constantsChoosed: {
        type: Object,
        required: true
      },
      constants: {
        type: Object,
        required: true
      },
      lang: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        openSection: null
      }
    },
    methods: {
      toggleSection(section) {
        this.openSection = this.openSection === section ? null : section
      },
      onCheckboxChange(label, key, filterType) {
        console.log(label);
        console.log(label);

            if (this.constantsChoosed[filterType][key]) {
                // Checkbox selected, add the label to the object
                this.$set(this.constantsChoosed[filterType], key, label);
            } else {
                // Checkbox unselected, remove the key from the object
                this.$delete(this.constantsChoosed[filterType], key);
            }
            this.$emit('filterChanged');
        },
      hasSelectedFilters(filterType) {
        return Object.values(this.constantsChoosed[filterType]).some(item => item)
      }
    },
    mounted() {
      // Emit the initial filterChanged event
      console.log(666666);
      
      console.log(this.constantsChoosed);
      
    }
  }
  </script>
  
  <style scoped>
  .filter-panels {
    max-width: 100%;
    overflow: hidden;
    border: 1px solid #e5e7eb;
  }
  
  .filter-section {
    border-bottom: 1px solid #e5e7eb;
  }
  
  .filter-section:last-child {
    border-bottom: none;
  }
  
  .filter-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1.5rem;
    background: white;
    border: none;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .filter-header:hover {
    background-color: #f9fafb;
  }
  
  .filter-title {
    font-weight: 600;
    color: #374151;
    font-size: 0.95rem;
  }
  
  .filter-icon {
    color: #6b7280;
    transition: transform 0.2s ease;
  }
  
  .rotate-180 {
    transform: rotate(180deg);
  }
  
  .filter-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out, padding 0.3s ease;
    background-color: #f9fafb;
  }
  
  .filter-content.expanded {
    min-height: 200px !important;
    max-height: 400px !important;
    padding: 1rem 1.5rem;
    overflow-y: auto;
  }
  
  .show-all {
    font-weight: 600;
    color: #4b5563;
    margin-bottom: 0.75rem;
    font-size: 0.9rem;
  }
  
  .filter-option {
    display: flex;
    align-items: center;
    padding: 0.5rem 0;
    cursor: pointer;
    position: relative;
  }
  
  .filter-checkbox {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  .checkmark {
    height: 18px;
    width: 18px;
    background-color: white;
    border: 2px solid #d1d5db;
    border-radius: 4px;
    margin-right: 1.5rem;
    position: relative;
    transition: all 0.2s ease;
  }
  
  .filter-checkbox:checked ~ .checkmark {
    background-color: #4f46e5;
    border-color: #4f46e5;
  }
  
  .filter-checkbox:checked ~ .checkmark:after {
    content: '';
    position: absolute;
    left: 5px;
    top: 2px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
  
  .label-text {
    color: #4b5563;
    font-size: 0.9rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  
  .filter-option:hover .checkmark {
    border-color: #4f46e5;
  }
  
  /* Custom Scrollbar */
  .filter-content::-webkit-scrollbar {
    width: 4px;
  }
  
  .filter-content::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }
  
  .filter-content::-webkit-scrollbar-thumb {
    background: #cbd5e1;
    border-radius: 4px;
  }
  
  .filter-content::-webkit-scrollbar-thumb:hover {
    background: #94a3b8;
  }
  </style>