<template>
  <v-dialog v-model="openMode" max-width="500px" content-class="modern-dialog">
    <v-card class="dialog-card">
      <!-- Dialog Header -->
      <div class="dialog-header">
        <v-icon color="white" size="32" class="mr-2">mdi-alert-circle</v-icon>
        <h2 class="text-h5 mb-0">אישור מחיקה</h2>
      </div>

      <!-- Dialog Content -->
      <v-card-text class="dialog-content">
        <div class="confirmation-message">
          <p class="message-text">האם אתה בטוח שברצונך למחוק את המסעדה?</p>
          <p class="user-details">
            <span class="detail-label">שם:</span>
            <span class="detail-value">{{ item.name }}</span>
          </p>
          <p class="user-details">
            <span class="detail-label">עיר: </span>
            <span class="detail-value">{{ item.city_web.text }}</span>
          </p>
        </div>
      </v-card-text>

      <!-- Loading Indicator -->
      <v-overlay :value="progressShow" absolute>
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>

      <!-- Dialog Actions -->
      <v-card-actions class="dialog-actions">
        <v-spacer></v-spacer>
        <v-btn
          text
          @click="closeDelete"
          class="action-button"
        >
          ביטול
        </v-btn>
        <v-btn
          color="error"
          @click="deleteItem"
        >
          <v-icon left>mdi-delete</v-icon>
          מחק
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
    <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

  </v-dialog>
</template>

<script>
import ApiServices from '@/services/api.service'
import SnackBar from '@/components/widgets/snackBar.vue'

export default {
  props: {
    item: Object,
    value: { type: Boolean, default: false },

  },
  components: {
    SnackBar,
  },
  data: () => ({
    progressShow: false,
    snackbar: false,
    snackbarColorBt: "green",
    snacbarText: "",
  }),

  methods: {
    async deleteItem() {
      if (this.item?._id) {
        try {
          this.progressShow = true;
          let token = localStorage.getItem("token");
          const myJSON = JSON.stringify({ item: this.item });

          let api = process.env.VUE_APP_BASE_URL + "/admin/delete_restaurant";
          const res = await fetch(api, ApiServices.requestOptions("DELETE", myJSON, token));
          const jsonObject = await res.json();
          this.progressShow = false;
          if (res.status >= 400) {
            this.showSnackBar("שגיאה במחיקת המסעדה: " + jsonObject.message, "red");
          } else if (res.status === 200) {
            this.$emit("itemDeleted", "המסעדה נמחק בהצלחה!", "green");
            this.openMode = false;
          }
        } catch (error) {
          this.progressShow = false;
          this.showSnackBar("Error delete item: " + error, "red");
        }
      }
    },
    closeDelete() {
      this.openMode = false;
    },
    showSnackBar(message, color) {
      this.snacbarText = message;
      this.snackbarColorBt = color;
      this.snackbar = true
    },
  },
  computed: {
    openMode: {
      get() {
        return this.value
      },
      set(v) {
        return this.$emit('input', v)
      }
    },
  },
  mounted() {
    console.log(this.item);

  }
}
</script>
<style lang="scss" scoped>
.modern-dialog {
  .dialog-card {
    border-radius: 12px;
    overflow: hidden;
  }

  .dialog-header {
    padding: 1.5rem;
    background: linear-gradient(135deg, #ef4444 0%, #b91c1c 100%);
    color: white;
    display: flex;
    align-items: center;
  }

  .dialog-content {
    padding: 2rem;

    .confirmation-message {
      text-align: center;

      .message-text {
        font-size: 1.1rem;
        color: #4a5568;
        margin-bottom: 1.5rem;
      }

      .user-details {
        margin: 0.5rem 0;
        
        .detail-label {
          color: #64748b;
          margin-left: 0.5rem;
        }

        .detail-value {
          color: #1e293b;
          font-weight: 500;
        }
      }
    }
  }

  .dialog-actions {
    padding: 1rem 1.5rem;
    background: #f8fafc;
    border-top: 1px solid rgba(0, 0, 0, 0.1);

    .action-button {
      min-width: 100px;
      text-transform: none;
      letter-spacing: 0.5px;
      font-weight: 500;
    }
  }
}

// RTL specific styles
[dir="rtl"] {
  .dialog-header {
    .v-icon {
      margin-left: 8px;
      margin-right: 0;
    }
  }

  .user-details {
    .detail-label {
      margin-right: 0;
      margin-left: 0.5rem;
    }
  }
}
</style>    