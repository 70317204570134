<template>
  <div class="verify-email-container" dir="rtl">
    <!-- Main verification card -->
    <div v-if="emailVerified !== 'null'" class="verification-card">
      <!-- Success State -->
      <div v-if="emailVerified === 'true'" class="status-container">
        <div class="icon-circle success">
          <i class="fas fa-check"></i>
        </div>
        <h1 class="status-title">האימות בוצע בהצלחה!</h1>
        <p class="status-message">תודה שאימתת את כתובת האימייל שלך. מיד תועבר לדף ההתחברות.</p>
      </div>

      <!-- Error State -->
      <div v-else-if="emailVerified === 'false'" class="status-container">
        <div class="icon-circle error">
          <i class="fas fa-times"></i>
        </div>
        <h1 class="status-title">האימות נכשל</h1>
        <p class="status-message">מצטערים, לא הצלחנו לאמת את כתובת האימייל שלך. אנא נסה שנית או צור קשר עם התמיכה.</p>
      </div>

      <!-- Already Verified State -->
      <div v-else class="status-container">
        <div class="icon-circle verified">
          <i class="fas fa-check-double"></i>
        </div>
        <h1 class="status-title">החשבון שלך כבר אומת</h1>
        <p class="status-message">אתה יכול להמשיך להתחבר למערכת.</p>
      </div>

      <!-- Action Button -->
      <button @click="$router.push({ name: 'login' })" 
              :class="['action-button', {
                'success': emailVerified === 'true',
                'error': emailVerified === 'false',
                'verified': emailVerified === 'alreadyConfirmed'
              }]">
        המשך להתחברות
      </button>
    </div>

    <!-- Loading State -->
    <div v-else class="loading-container">
      <div class="loader"></div>
      <p class="loading-text">מאמת את כתובת האימייל שלך...</p>
    </div>

    <!-- Snackbar Notification -->
    <div :class="['snackbar', { 'show': snackbar }]" :style="{ backgroundColor: snackbarColorBt === 'red' ? '#dc2626' : '#16a34a' }">
      <span class="snackbar-text">{{ snacbarText }}</span>
      <button @click="snackbar = false" class="snackbar-close">
        סגור
      </button>
    </div>
  </div>
</template>

<script>
import ApiServices from '@/services/api.service'

export default {
  name: 'VerifyEmailView',
  data() {
    return {
      snackbar: false,
      snackbarColorBt: "green",
      snacbarText: "",
      emailVerified: "null"
    }
  },
  methods: {
    async verifyEmail() {
      let token = this.$route.params.token;
      try {
        let api = process.env.VUE_APP_BASE_URL + "/auth/verify_email";
        const res = await fetch(api, ApiServices.requestOptions("GET", "", token));
        
        if (res.status >= 400) {
          const jsonObject = await res.json();
          this.showSnackBar("שגיאה באימות האימייל: " + jsonObject.message, "red");
          this.emailVerified = "false";
        } else if (res.status === 201) {
          this.emailVerified = "alreadyConfirmed";
          await this.sleep(1700);
          this.$router.replace({ name: "login"});
        } else if (res.status === 200) {
          this.showSnackBar("כתובת המייל אומתה בהצלחה!", "green");
          this.emailVerified = "true";
          await this.sleep(1700);
          this.$router.replace({ name: "login"});
        }
      } catch (error) {
        this.showSnackBar("שגיאה באימות האימייל: " + error, "red");
      }
    },
    showSnackBar(message, color) {
      this.snacbarText = message;
      this.snackbarColorBt = color;
      this.snackbar = true;
    },
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }
  },
  created() {
    this.verifyEmail();
  }
}
</script>

<style scoped>
.verify-email-container {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f8fafc;
  padding: 1rem;
}

.verification-card {
  background: white;
  border-radius: 16px;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  padding: 2.5rem;
  width: 100%;
  max-width: 480px;
  text-align: center;
}

.status-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.icon-circle {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  transition: all 0.3s ease;
}

.icon-circle.success {
  background-color: #f0fdf4;
  color: #16a34a;
  border: 2px solid #86efac;
}

.icon-circle.error {
  background-color: #fef2f2;
  color: #dc2626;
  border: 2px solid #fca5a5;
}

.icon-circle.verified {
  background-color: #f0f9ff;
  color: #0284c7;
  border: 2px solid #7dd3fc;
}

.status-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #1e293b;
  margin: 0;
}

.status-message {
  color: #64748b;
  font-size: 1rem;
  line-height: 1.6;
  margin: 0;
  max-width: 90%;
}

.action-button {
  background-color: #3b82f6;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 0.875rem 1.5rem;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  width: 100%;
  margin-top: 1rem;
}

.action-button.success {
  background-color: #16a34a;
}

.action-button.error {
  background-color: #dc2626;
}

.action-button.verified {
  background-color: #0284c7;
}

.action-button:hover {
  filter: brightness(110%);
  transform: translateY(-1px);
}

.action-button:active {
  transform: translateY(0);
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
}

.loader {
  width: 48px;
  height: 48px;
  border: 3px solid #e2e8f0;
  border-top: 3px solid #3b82f6;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.loading-text {
  color: #64748b;
  font-size: 1rem;
  margin: 0;
}

.snackbar {
  position: fixed;
  bottom: 24px;
  left: 50%;
  transform: translateX(-50%) translateY(100px);
  padding: 1rem 1.5rem;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 1rem;
  transition: all 0.3s ease;
  z-index: 1000;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1);
}

.snackbar.show {
  transform: translateX(-50%) translateY(0);
}

.snackbar-text {
  color: white;
  font-size: 0.875rem;
}

.snackbar-close {
  background: none;
  border: none;
  color: white;
  font-size: 0.875rem;
  cursor: pointer;
  padding: 0;
  opacity: 0.8;
  transition: opacity 0.2s ease;
}

.snackbar-close:hover {
  opacity: 1;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media (max-width: 480px) {
  .verification-card {
    padding: 1.5rem;
  }
  
  .icon-circle {
    width: 56px;
    height: 56px;
    font-size: 1.25rem;
  }
  
  .status-title {
    font-size: 1.25rem;
  }
}
</style>