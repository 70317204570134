<template>
  <v-app style="background-color: #f5f7fa;">
    <div style="height: 50px; display: flex; justify-content: center; align-items: center;">
      <v-tabs v-model="activeTab" centered background-color="#062a4e" height="50px">
        <v-tabs-slider color="rgb(238, 242, 246)"></v-tabs-slider>
        <v-tab v-for="tab in tabs" :key="tab" class="text-center" color="rgb(238, 242, 246)" @click="setComponent(tab)">
          <span style="font-weight: bold; color: white; text-transform: none;">{{ tab[$i18n.locale] }}</span>
        </v-tab>
      </v-tabs>
    </div>

    <v-container class="mt-6">
      <v-fade-transition mode="out-in">
        <AddRestaurant v-if="addRest"></AddRestaurant>
        <EditRestaurant v-if="editRest"></EditRestaurant>
        <userSettings v-if="userSettings"></userSettings>
      </v-fade-transition>
    </v-container>
  </v-app>
</template>

<script>
import AddRestaurant from '@/components/user/AddRestaurant'
import EditRestaurant from '@/components/user/EditRestaurant'
import userSettings from '@/components/user/SettingsComp'
import Auth from '@/services/auth.service.js'

export default {
  components: {
    AddRestaurant,
    EditRestaurant,
    userSettings,
  },
  data() {
    return {
      addRest: false,
      editRest: false,
      userSettings: true,
      activeTab: 0,
      tabs: [{he: "הגדרות לקוח" , en: "Customer settings"}, {he: "הוספת מסעדה חדשה" , en:"Add a new restaurant"}]
    }
  },
  methods: {
    updatePageTitle() {
      if (this.$vuetify.rtl) {
        return document.title = 'כשר בסביבה | אזור אישי'
      } else {
        return document.title = 'Kosher Basviva | Account';
      }
    },
    setComponent(tab) {
      if (tab["he"] === "הגדרות לקוח") {
        this.addRest = false;
        this.editRest = false;
        this.userSettings = true;
      } else if (tab["he"] === "הוספת מסעדה חדשה") {
        this.addRest = true;
        this.editRest = false;
        this.userSettings = false;
      }
    }
  },
  mounted() {
    let auth = Auth.checkAuth();
    if (!auth) {
      this.$router.replace({ name: "home" })
    }
    this.updatePageTitle();

    if (this.$route.params._id) {
      this.editRest = true;
      this.addRest = false;
      this.userSettings = false;
      this.activeTab = 1;
    } else if (this.$route.params.addRest) {
      this.editRest = false;
      this.addRest = true;
      this.userSettings = false;
      this.activeTab = 1;
    }
  },
};
</script>

<style scoped></style>